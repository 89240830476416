<template>
  <div
    class="modal fade"
    id="modalJsonViewer"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="modalJsonViewerLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header p-4">
          <h5 class="modal-title" id="modalJsonViewerLabel">
            <i class="bi bi-filetype-json"></i>
            {{
              $t(
                "Components.JsonViewerModal.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body p-0">
          <json-viewer
            :value="json"
            :expand-depth="5"
            copyable
            sort
          ></json-viewer>
        </div>
        <div class="modal-footer p-4">
          <button
            type="button"
            class="btn btn-danger btn-sm"
            data-bs-dismiss="modal"
          >
            <i class="fa fa-times"></i>
            {{
              $t(
                "BaseModelFields.Close",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-json-viewer/style.css";
import JsonViewer from "vue-json-viewer";
export default {
  name: "JsonViewerModal",
  props: ["json"],
  data() {
    return {
      model: {},
    };
  },
  components: {
    JsonViewer,
  },
  methods: {},
};
</script>
