<template>
  <div
    class="form-file-list"
    :class="parentCssClasses"
    v-if="uploadedFiles.length > 0"
  >
    <ul class="list-inline">
      <li v-for="item in uploadedFiles" :key="item.Id" :data-id="item.Id">
        <div v-if="onlyView && isDetailPage && field.fieldType === 'Image'">
          <span v-if="field.property === 'only_view_on_detail'">
            <div class="d-grid">
              <div class="img__wrap w-50 h-100">
                <img
                  :class="`preview_${item.Id}`"
                  :alt="field.name"
                  src=""
                  class="img-thumbnail"
                />
                <p class="img__description">
                  <a
                    class="btn btn-icon"
                    data-bs-toggle="modal"
                    :data-bs-target="
                      String.format('#modalImageView_{0}', field.publicId)
                    "
                  >
                    <i class="bi bi-arrows-fullscreen fs-1"></i>
                  </a>
                </p>
              </div>
            </div>
          </span>
          <span v-else-if="field.property === 'only_view_on_modal'">
            <button
              type="button"
              class="btn btn-light w-50"
              data-bs-toggle="modal"
              :data-bs-target="
                String.format('#modalImageView_{0}', field.publicId)
              "
            >
              {{
                $t(
                  "Components.ImageViewModal.View",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </span>
          <span v-else>
            {{ item.Name }}
            <span
              class="ms-2 cursor-pointer"
              @click="download(item.Id, item.Name)"
            >
              <i class="fas fa-download text-primary"></i>
            </span>
          </span>
        </div>
        <div v-else>
          <span v-if="$root.isDocumentViewerFile(item.Name)">
            <!-- :href="String.format('/document/viewer/{0}', item.Id)"
              target="_blank" -->
            <a>{{ item.Name }}</a>
            <span
              class="ms-2 cursor-pointer"
              @click="download(item.Id, item.Name)"
            >
              <i class="fas fa-download text-primary"></i>
            </span>
            <i
              @click="removeFile(item.Id)"
              v-if="!onlyView"
              class="ms-2 fas fa-times cursor-pointer text-danger"
            ></i>
          </span>
          <span v-else>
            <span class="cursor-pointer" @click="download(item.Id, item.Name)">
              {{ item.Name }}
            </span>
            <i
              @click="removeFile(item.Id)"
              v-if="!onlyView"
              class="ms-2 fas fa-times cursor-pointer text-danger"
            ></i>
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { createToast } from "mosha-vue-toastify";
export default {
  name: "FormFileList",
  props: ["uploadedFiles", "field", "parentCssClasses", "onlyView"],
  data() {
    return {
      isDetailPage: this.$route.meta.isDetailPage,
    };
  },
  methods: {
    download(id, name) {
      this.$emit("download", { id, name });
    },
    removeFile(id) {
      this.$emit("removeFile", { id });
    },
  },
};
</script>
