<template>
  <template v-if="loaderType === 'spinner-message'">
    <div class="page-loader flex-column no-selection">
      <span class="spinner-border text-primary" role="status"></span>
      <span class="text-muted fs-6 fw-bold mt-5">
        {{
          this.$t(
            "BaseModelFields.PleaseWait",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</span
      >
    </div>
  </template>
  <template v-else-if="loaderType === 'spinner-logo'">
    <div class="page-loader flex-column no-selection">
      <img alt="Logo" class="max-h-75px" :src="$config.media.logos.dark" />

      <div class="d-flex align-items-center mt-5">
        <span class="spinner-border text-primary" role="status"></span>
        <span class="text-muted fs-6 fw-bold ms-5">
          {{
            this.$t(
              "BaseModelFields.PleaseWait",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</span
        >
      </div>
    </div>
  </template>
  <template v-else>
    <div class="page-loader no-selection" id="app-preloader">
      <div class="preloader-logo">
        <img
          alt="SetXrm Cloud Solutions"
          class="img-fluid"
          width="300"
          :src="$config.media.logos.dark"
        />
      </div>
      <div class="preloader-title">
        {{
          this.$t(
            "BaseModelFields.PleaseWait",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </div>
      <div class="spinner-border text-white w-3rem h-3rem" role="status">
        <span class="visually-hidden">
          {{
            this.$t(
              "BaseModelFields.PleaseWait",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</span
        >
      </div>
    </div>
  </template>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "AppLoader",
  data() {
    return {};
  },
  setup() {
    const store = useStore();

    const loaderType = computed(() => {
      return store.getters.layoutConfig("loader.type");
    });

    return {
      loaderType,
    };
  },
};
</script>
