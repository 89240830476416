<template>
  <div class="set-loader no-selection">
    <img
      alt="SetXrm Cloud Solutions"
      class="img-fluid"
      :style="$config.media.logos.loaderStyle"
      :src="$config.media.logos.loader"
    />
    <!--  <div class="set-loader-main">
      <div class="set-loader-element text-center"></div>
     <div class="fw-bolder set-loader-element fs-4 text-primary">
        {{
          this.$t(
            "BaseModelFields.PageDesignLoading",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </div>
      <div class="set-loader-loading set-loader-element set-loader-title"></div>
      <div
        class="set-loader-loading set-loader-element set-loader-subtitle"
      ></div>
    </div> -->
  </div>
</template>
<script>
import "@/assets/css/set-loader.css";
export default {
  name: "SetLoader",
  props: {
    isDocumentTitleChange: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  mounted() {
    if (this.isDocumentTitleChange) {
      this.$root.changeDocumentTitle(
        this.$t("BaseModelFields.PageDesignLoading"),
        {},
        { locale: this.$store.state.activeLang }
      );
    }
  },
};
</script>
