<template>
  <div :class="parentClasses">
    <template v-if="response && response.notificationType == 'TextPopup'">
      <table class="table table-hover table-borderless fs-5 mb-0">
        <thead class="table-primary">
          <tr>
            <th>
              {{
                this.$t(
                  "Components.ValidationMessages.Message",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </th>
          </tr>
        </thead>
        <tbody>
          <td>
            {{ response.message }}
          </td>
        </tbody>
      </table>
    </template>
    <template v-if="errors.length > 0">
      <div v-if="response.notificationType == 'ModalPopup'">
        <div
          class="alert btn-sx-expand collapsed cursor-pointer fs-5 mb-0"
          :class="{
            'alert-success': response.isOk,
            'alert-danger': !response.isOk,
          }"
          data-bs-toggle="collapse"
          :data-bs-target="String.format('#collapse-panel')"
        >
          <template v-if="response.isOk">
            {{
              this.$t(
                "BaseModelFields.BusinessRuleSuccessMessage",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </template>
          <template v-else>
            {{
              this.$t(
                "BaseModelFields.BusinessRuleErrorMessage",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </template>
          <span>
            {{
              this.$t(
                "BusinessRules.ClickToSeeDetails",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}<i class="bi bi-arrow-down-short fs-5"></i></span
          ><b>
            {{
              this.$t(
                "BusinessRules.Hide",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}<i class="bi bi-arrow-up-short fs-5 fw-bold"></i
          ></b>
        </div>
        <div
          :id="String.format('collapse-panel')"
          class="accordion-collapse collapse"
        >
          <div class="me-3 mt-3">
            <div class="table-responsive">
              <table class="table table-hover table-borderless fs-5 mb-0">
                <thead class="table-primary">
                  <tr>
                    <th>
                      {{
                        this.$t(
                          "BusinessRules.BusinessRuleName",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                    <th>
                      {{
                        this.$t(
                          "BusinessRules.BusinessRuleActionName",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                    <th class="text-center">
                      {{
                        this.$t(
                          "BusinessRules.IsSync",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                    <th class="text-center">
                      {{
                        this.$t(
                          "BusinessRules.IsOk",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                    <th class="text-center">
                      {{
                        this.$t(
                          "BusinessRules.Details",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody v-for="(businessRule, i) in brResponses" :key="i">
                  <template v-if="businessRule.actionResponses.length == 0">
                    <tr>
                      <td class="align-middle fw-bold text-capitalize">
                        {{ businessRule.businessRuleName }}
                      </td>
                      <td class="text-capitalize">
                        <b><i class="fa fa-minus"></i> </b>
                      </td>
                      <td class="text-center">
                        <b><i class="fa fa-minus"></i> </b>
                      </td>
                      <td class="text-center">
                        <b><i class="fa fa-minus"></i> </b>
                      </td>
                      <td class="text-center">
                        <a
                          v-if="
                            !String.isNullOrWhiteSpace(businessRule.message) ||
                            businessRule.isOk == null
                          "
                          class="btn btn-success btn-sx-expand collapsed"
                          data-bs-toggle="collapse"
                          :data-bs-target="
                            String.format(
                              '#collapse{0}',
                              businessRule.businessRulePublicId
                            )
                          "
                          role="button"
                        >
                          <span>
                            {{
                              this.$t(
                                "BusinessRules.Show",
                                {},
                                { locale: this.$store.state.activeLang }
                              )
                            }}</span
                          ><b>
                            {{
                              this.$t(
                                "BusinessRules.Hide",
                                {},
                                { locale: this.$store.state.activeLang }
                              )
                            }}</b
                          >
                        </a>
                        <i class="fa fa-minus" v-else></i>
                      </td>
                    </tr>
                    <tr
                      v-if="businessRule.isOk == null"
                      class="hide-table-padding"
                    >
                      <td
                        colspan="7"
                        class="pp-0"
                        v-if="
                          !String.isNullOrWhiteSpace(businessRule.message) ||
                          businessRule.isOk == null
                        "
                      >
                        <div
                          :id="
                            String.format(
                              'collapse{0}',
                              businessRule.businessRulePublicId
                            )
                          "
                          class="accordion-collapse collapse"
                        >
                          <div class="me-3 mt-3 alert">
                            {{ businessRule.message }}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr
                      v-for="(
                        businessRuleAction, y
                      ) in businessRule.actionResponses.filter(
                        (f) => f.isResponseReturnToClient
                      )"
                      :key="y"
                    >
                      <td
                        class="align-middle fw-bold text-capitalize"
                        :rowspan="
                          businessRule.actionResponses.filter(
                            (f) => f.isResponseReturnToClient
                          ).length
                        "
                        v-if="y == 0"
                      >
                        {{ businessRule.businessRuleName }}
                      </td>
                      <td class="text-capitalize">
                        {{ businessRuleAction.businessRuleActionName }}
                      </td>
                      <td class="text-center">
                        <b v-if="businessRuleAction.isSynchAction"
                          ><i class="bi bi-check fa-2x text-success"></i
                        ></b>
                        <b v-else><i class="bi bi-x fa-2x text-danger"></i> </b>
                      </td>
                      <td class="text-center">
                        <b v-if="businessRule.isOk == null"
                          ><i class="fa fa-minus fa-2x"></i>
                        </b>
                        <template v-else>
                          <b v-if="businessRuleAction.isOk"
                            ><i class="bi bi-check fa-2x text-success"></i
                          ></b>
                          <b v-else
                            ><i class="bi bi-x fa-2x text-danger"></i>
                          </b>
                        </template>
                      </td>
                      <td class="text-center">
                        <a
                          v-if="
                            businessRuleAction.validations.length > 0 ||
                            !String.isNullOrWhiteSpace(
                              businessRuleAction.message
                            ) ||
                            businessRule.isOk == null
                          "
                          class="btn btn-success btn-sx-expand collapsed"
                          data-bs-toggle="collapse"
                          :data-bs-target="
                            String.format(
                              '#collapse{0}',
                              businessRuleAction.businessRuleActionPublicId
                            )
                          "
                          role="button"
                        >
                          <span>
                            {{
                              this.$t(
                                "BusinessRules.Show",
                                {},
                                { locale: this.$store.state.activeLang }
                              )
                            }}</span
                          ><b>
                            {{
                              this.$t(
                                "BusinessRules.Hide",
                                {},
                                { locale: this.$store.state.activeLang }
                              )
                            }}</b
                          >
                        </a>
                        <button
                          v-else-if="
                            !String.isNullOrWhiteSpace(
                              businessRuleAction.responseText
                            ) && businessRuleAction.downloadResponseAsDocument
                          "
                          type="button"
                          class="btn btn-dark"
                          @click="onJsonViewerButtonClick(businessRuleAction)"
                        >
                          <i class="bi bi-filetype-json me-2"></i>
                          {{
                            $t(
                              "BusinessRules.ResponseText",
                              {},
                              { locale: this.$store.state.activeLang }
                            )
                          }}
                        </button>
                        <i class="fa fa-minus" v-else></i>
                      </td>
                    </tr>
                    <tr
                      v-for="(
                        businessRuleAction, y
                      ) in businessRule.actionResponses.filter(
                        (f) => f.isResponseReturnToClient
                      )"
                      :key="y"
                      class="hide-table-padding"
                    >
                      <td
                        colspan="7"
                        class="pp-0"
                        v-if="
                          businessRuleAction.validations.length > 0 ||
                          !String.isNullOrWhiteSpace(businessRuleAction.message)
                        "
                      >
                        <div
                          :id="
                            String.format(
                              'collapse{0}',
                              businessRuleAction.businessRuleActionPublicId
                            )
                          "
                          class="accordion-collapse collapse"
                        >
                          <div class="me-3 mt-3">
                            <div
                              class="alert"
                              :class="{
                                'alert-danger': !businessRuleAction.isOk,
                                'alert-success': businessRuleAction.isOk,
                              }"
                              v-if="
                                !String.isNullOrWhiteSpace(
                                  businessRuleAction.message
                                )
                              "
                            >
                              {{ businessRuleAction.message }}
                            </div>
                            <table
                              class="table table-striped table-hover fs-5"
                              v-if="businessRuleAction.validations.length > 0"
                            >
                              <thead class="table-info">
                                <tr>
                                  <th>#</th>
                                  <th>
                                    {{
                                      this.$t(
                                        "Components.ValidationMessages.Type",
                                        {},
                                        {
                                          locale: this.$store.state.activeLang,
                                        }
                                      )
                                    }}
                                  </th>
                                  <th>
                                    {{
                                      this.$t(
                                        "Components.ValidationMessages.FieldName",
                                        {},
                                        {
                                          locale: this.$store.state.activeLang,
                                        }
                                      )
                                    }}
                                  </th>
                                  <th>
                                    {{
                                      this.$t(
                                        "Components.ValidationMessages.Message",
                                        {},
                                        {
                                          locale: this.$store.state.activeLang,
                                        }
                                      )
                                    }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(
                                    businessRuleActionValidation, y
                                  ) in businessRuleAction.validations"
                                  :key="y"
                                >
                                  <td>{{ i + 1 }}.{{ ++y }}</td>
                                  <td>
                                    {{
                                      this.$t(
                                        "BaseModelFields." +
                                          businessRuleActionValidation
                                            .validationType.displayName,
                                        {},
                                        {
                                          locale: this.$store.state.activeLang,
                                        }
                                      )
                                    }}
                                  </td>
                                  <td>
                                    {{ businessRuleActionValidation.fieldName }}
                                  </td>
                                  <td>
                                    {{ businessRuleActionValidation.message }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { showModal } from "@/core/helpers/dom";
export default {
  name: "BusinessRuleResponseGrid",
  props: {
    parentClasses: {
      type: String,
      default: "",
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
    response: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  components: {},
  data() {
    return {
      brResponses: [],
      brActionResponses: [],
      brActionValidationResponses: [],
      isDrawResponseColumn: false,
    };
  },
  watch: {
    errors: {
      deep: true,
      handler() {
        this.brResponses = [];
        this.brActionResponses = [];
        this.brActionValidationResponses = [];

        this.brResponses = this.errors.filter(function (f) {
          return !f.isNotShowResultToClient;
        });

        if (this.brResponses.length > 0) {
          var parentComponent = this.$parent;
          if (
            parentComponent &&
            parentComponent.$options &&
            parentComponent.$options.name === "InlineErrorModal"
          ) {
            if (
              parentComponent.inlineErrors &&
              parentComponent.inlineErrors.length > 0
            ) {
              parentComponent.modal = {
                icon: "bi bi-file-text",
                title: this.$t(
                  "Components.InlineErrorModal.InlineAndBusinessRuleErrors",
                  {},
                  { locale: this.$store.state.activeLang }
                ),
              };
            } else {
              parentComponent.modal = {
                icon: "fas fa-bolt",
                title: this.$t(
                  "BusinessRules.BusinessRuleErrors",
                  {},
                  { locale: this.$store.state.activeLang }
                ),
              };
            }
          }
        }

        this.brResponses.forEach((businessRule) => {
          businessRule.actionResponses.forEach((businessRuleAction) => {
            if (!String.isNullOrWhiteSpace(businessRuleAction.responseText)) {
              this.isDrawResponseColumn = true;
            }
            if (businessRuleAction.isResponseReturnToClient) return;
            this.brActionResponses.push(businessRuleAction);
            businessRuleAction.validations.forEach(
              (businessRuleActionValidation) => {
                this.brActionValidationResponses.push(
                  businessRuleActionValidation
                );
              }
            );
          });
        });
      },
    },
  },
  methods: {
    onJsonViewerButtonClick(businessRuleAction) {
      this.$root.json = String.isJSON(businessRuleAction.responseText)
        ? JSON.parse(businessRuleAction.responseText)
        : businessRuleAction.responseText;
      showModal(document.getElementById("modalSetPagesJsonViewer"));
    },
  },
};
</script>
