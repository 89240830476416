import LayoutConfigTypes from "@/core/config/LayoutConfigTypes";

const config: LayoutConfigTypes = {
  themeName: "SetXRM",
  themeVersion: "2.0.0",
  demo: "",
  main: {
    type: "default",
    primaryColor: "#009EF7",
    logo: {
      dark: "/extras/media/logos/setxrm_logo_disi.webp",
      light: "/extras/media/logos/setxrm_logo_disi.webp",
    },
  },
  loader: {
    logo: "/extras/media/logos/setxrm_logo_disi.webp",
    display: true,
    type: "default",
  },
  scrollTop: {
    display: true,
  },
  header: {
    display: true,
    menuIcon: "font",
    width: "fluid",
    fixed: {
      desktop: false,
      tabletAndMobile: false,
    },
  },
  toolbar: {
    display: true,
    width: "fluid",
    fixed: {
      desktop: false,
      tabletAndMobile: false,
    },
  },
  aside: {
    display: true,
    theme: "dark",
    fixed: true,
    menuIcon: "svg",
    minimized: false,
    minimize: true,
    hoverable: true,
  },
  content: {
    width: "fluid",
  },
  footer: {
    width: "fluid",
  },
};

export default config;
