<template>
  <div class="view-edit-mode" :class="{ hidden: isDetailPage }">
    <div class="position-relative">
      <input
        ref="phoneInput"
        type="tel"
        class="form-control text-indent-35"
        v-model="phone"
        :id="element.id"
        :name="element.id"
        :disabled="isDisabled"
        :class="
          element.valueClassNames !== undefined ? element.valueClassNames : ''
        "
        :placeholder="placeholder"
        :autocomplete="$isAutoComplete"
        :required="this.field.isRequired || this.element.isRequired"
        :data-oldvalue="oldValue"
      />
    </div>
  </div>
  <div class="view-detail-mode" v-if="isDetailPage">
    <span class="d-flex text-break">
      <span v-if="!String.isNullOrWhiteSpace(phone)">
        <a :href="String.format('tel:{0}', phone)">
          <i class="fas fa-phone text-primary"></i> {{ phone }}</a
        >
      </span>
      <span v-else>{{ this.$valueNotAvailableSeparator }}</span>
    </span>
  </div>
</template>
<script>
export default {
  props: ["element", "field", "value", "crudType", "isCellEdit", "rowData"],
  data() {
    return {
      isNewPage: this.crudType === "NEW",
      isDetailPage: this.crudType === "DETAIL",
      isDisabled:
        !this.field.isActive ||
        this.element.isDisabled ||
        (this.$route.meta.isEditPage && !this.field.isEditable),
      placeholder: this.field.fieldType.includes("Formula")
        ? this.$t(
            "BaseModelFields.FieldIsCalcForSystem",
            {},
            { locale: this.$store.state.activeLang }
          )
        : "",
      phone: this.value,
      oldValue: "",
    };
  },
  methods: {
    setValue(isSuccess, value) {
      if (isSuccess) {
        var phoneInput = document.getElementById(this.element.id);
        phoneInput.setAttribute("data-oldvalue", value);
        this.oldValue = value;
      } else {
        this.phone = this.oldValue;
      }
    },
  },
  mounted() {
    if (
      !this.field.fieldType.includes("Formula") &&
      !String.isNullOrWhiteSpace(this.value)
    ) {
      this.phone = this.value;
      this.oldValue = this.value;
    } else {
      this.oldValue = "";
    }

    var phoneInput = document.getElementById(this.element.id);
    if (!String.isNullOrWhiteSpace(phoneInput)) {
      this.$root.preparePhoneNumber(this.element.id, this.phone);

      var div = document.createElement("div");
      div.classList.add("invalid-tooltip");
      div.innerHTML = this.$t(
        "FieldWarnings.TextNotValid",
        {},
        { locale: this.$store.state.activeLang }
      );
      phoneInput.closest("div").appendChild(div);
    }
  },
};
</script>
