import $ from 'jquery';
import {
    createToast
} from "mosha-vue-toastify";
import intlTelInput from "intl-tel-input";
import Inputmask from "inputmask";
import dxDateBox from "devextreme/ui/date_box";
import dxDataGrid from "devextreme/ui/data_grid";
import {
    appAxios
} from "@/core/axios/appAxios.js";

import {
    createI18n
} from "vue-i18n/index";
import en from "@/locales/en.json";
import tr from "@/locales/tr.json";
const i18n = createI18n({
        locale: localStorage.getItem('activeLang'),
        messages: {
            en: en,
            tr: tr,
        },
    }),
    dxDataGridSettings = [{
            name: "elementAttr",
            value: {}
        }, {
            name: "keyExpr",
            value: "ID"
        }, {
            name: "allowColumnReordering",
            value: true
        }, {
            name: "allowColumnResizing",
            value: true
        }, {
            name: "width",
            value: "100%"
        }, {
            name: "isVisible",
            value: true
        }, {
            name: "headerIsVisible",
            value: true
        }, {
            name: "filterRowIsVisible",
            value: false
        }, {
            name: "groupPanelIsVisible",
            value: false
        }, {
            name: "pagerIsVisible",
            value: true
        }, {
            name: "rtlEnabled",
            value: false
        }, {
            name: "wordWrapEnabled",
            value: false
        }, {
            name: "headerItemsIsDisabled",
            value: false
        }, {
            name: "columnAutoWidth",
            value: true
        }, {
            name: "hoverStateEnabled",
            value: true
        }, {
            name: "focusedRowEnabled",
            value: true
        }, {
            name: "showRowLines",
            value: true
        }, {
            name: "rowAlternationEnabled",
            value: true
        }, {
            name: "selectionMode",
            value: "single"
        }, {
            name: "selectionMode",
            value: "single"
        }, {
            name: "headerItems",
            value: ["columnChooserButton", "searchPanel"]
        },
        {
            name: 'onSelectionChanged',
            value: function () {}
        },
        {
            name: 'onCellPrepared',
            value: null
        },
        {
            name: 'onRowPrepared',
            value: null
        }
    ],
    sxIframeDefaultSettings = [{
            name: "isToolbarShow",
            value: true
        },
        {
            name: "id",
            value: ""
        },
        {
            name: "classes",
            value: "",
        }
    ],
    formInvalidClasses = [
        ".file-invalid",
        ".html-editor-invalid",
        ".select2-invalid",
    ],
    availableListenerTypes = ["SXGRID_ON_PAGING", "SXGRID_ON_SORTING", "SXGRID_ON_REFRESH", "SXGRID_ON_SEARCH", "SXGRID_GETDATA_FINALLY", "SXGRID_GETDATA_REQUEST_UNSUCCESSFUL", "SXGRID_CONTENT_READY", "SXGRID_INITIALIZED", "SXGRID_INIT_NEW_ROW", "SX_NEW_RECORD_CREATED", "SX_EDIT_RECORD_UPDATED", "SXGRID_ON_SAVE", "SXGRID_EDITING_CHANGES"],
    sxFunctionsInformations = [{
            id: "deleteRecord",
            type: 'async',
            parameterType: 'Query String Parameter',
            parameters: [{
                name: 'customObjectKey',
                type: 'String',
                required: true,
            }, {
                name: 'recordPublicId',
                type: 'Guid',
                required: true,
            }],
            description: 'Kayıt silme işlemi gerçekleştirir.',
            example: "SX.deleteRecord('musteri', '798d8a29-xxxx-xxx-xxxx-77733ac6cabd').then((r) => {}).catch((c) => {});",
        }, {
            id: 'getRecords',
            type: "async",
            parameterType: 'Object Model Parameters',
            parameters: [{
                    name: 'CustomObjectKey',
                    type: 'String',
                    required: true,
                },
                {
                    name: 'ViewFilterName',
                    type: 'String',
                    required: true,
                }, {
                    name: 'Query',
                    type: 'String',
                    required: false,
                }, {
                    name: 'PageSize',
                    type: 'Number',
                    required: false,
                }, {
                    name: 'PageNumber',
                    type: 'Number',
                    required: false,
                },
                {
                    name: 'FieldFormulaNames',
                    type: "List<String>",
                    required: false
                }
            ],
            description: 'Kayıtları liste olarak çekme işlemi gerçekleştirir.',
            example: "SX.getRecords({CustomObjectKey: 'firma', ViewFilterName: '', PageSize: 50, PageNumber:1 }).then((r) => {}).catch((c) => {});",
        }, {
            id: 'getRecordsCount',
            type: "async",
            parameterType: 'Object Model Parameters',
            parameters: [{
                    name: 'CustomObjectKey',
                    type: 'String',
                    required: true,
                },
                {
                    name: 'ViewFilterName',
                    type: 'String',
                    required: true,
                }, {
                    name: 'Query',
                    type: 'String',
                    required: false,
                }, {
                    name: 'PageSize',
                    type: 'Number',
                    required: false,
                }, {
                    name: 'PageNumber',
                    type: 'Number',
                    required: false,
                }
            ],
            description: 'Kayıt sayısını döner.',
            example: "SX.getRecordsCount({CustomObjectKey: 'firma', ViewFilterName: '' }).then((r) => {console.log(r.count)}).catch((c) => {});",
        }, {
            id: 'getRecord',
            type: "async",
            parameterType: 'Object Model Parameters',
            parameters: [{
                    name: 'PublicId',
                    type: 'Guid',
                    required: true,
                },
                {
                    name: 'CustomObjectKey',
                    type: 'String',
                    required: true,
                }, {
                    name: 'PageLayoutFormulaName',
                    type: 'String',
                    required: false,
                },
                {
                    name: 'FieldFormulaNames',
                    type: "List<String>",
                    required: false
                }
            ],
            description: 'Kayıt detayını çekme işlemi gerçekleştirir.',
            example: "SX.getRecord({CustomObjectKey: 'firma', PublicId: 'RECORD_PUBLIC_ID' }).then((r) => {}).catch((c) => {});",
        }, {
            id: 'getRelationalRecords',
            type: "async",
            parameterType: 'Object Model Parameters',
            parameters: [{
                    name: 'ParentRecordPublicId',
                    type: 'Guid',
                    required: true,
                },
                {
                    name: 'LookupRelationFormulaName',
                    type: 'String',
                    required: true,
                }, {
                    name: 'ViewFilterName',
                    type: 'String',
                    required: false,
                },
                {
                    name: 'Query',
                    type: 'String',
                    required: false,
                },
                {
                    name: 'PageSize',
                    type: 'Number',
                    required: false,
                },
                {
                    name: 'PageNumber',
                    type: 'Number',
                    required: false,
                },
                {
                    name: 'FieldFormulaNames',
                    type: "List<String>",
                    required: false
                }
            ],
            description: 'Bir kayıta bağlı ilişkili kayıtları liste olarak çekme işlemi gerçekleştirir.',
            example: "SX.getRelationalRecords(LookupRelationFormulaName: 'X', ViewFilterName: '' }).then((r) => {}).catch((c) => {});",
        }, {
            id: 'createRecord',
            type: "async",
            parameterType: 'Object Model Parameters',
            parameters: [{
                name: 'CustomObjectKey',
                type: 'Guid',
                required: true,
            }, ],
            description: 'Kayıt oluşturma işlemini gerçekleştirir.',
            example: `SX.createRecord(
        {
            CustomObjectKey: 'firma',
            "PageLayoutFormulaName":"",
            "FieldsValues":[
            {
                "Key":"BAGLI_OLD_POS_GIRISI",
                "Value":"6ddde301-xxxx-xxxx-xxxx-e9a7ddf45a69"
            },
            {
                "Key":"ISLEM_NO",
                "Value":"212912300"
            }] 
        }).then((r) => {}).catch((c) => {});`,
        }, {
            id: 'updateRecord',
            type: "async",
            parameterType: 'Object Model Parameters',
            parameters: [{
                name: 'CustomObjectKey',
                type: 'Guid',
                required: true,
            }, ],
            description: 'Kayıt güncelleme işlemini gerçekleştirir.',
            example: `SX.updateRecord(
        {
            CustomObjectKey: 'firma',
            "PageLayoutFormulaName":"",
            "RecordPublicId:"x",
            "FieldsValues":[
            {
                "Key":"BAGLI_OLD_POS_GIRISI",
                "Value":"6ddde301-xxxx-xxxx-xxxx-e9a7ddf45a69"
            },
            {
                "Key":"ISLEM_NO",
                "Value":"212912300"
            }] 
        }).then((r) => {}).catch((c) => {});`,
        }, {
            id: 'formIsValid',
            type: "sync",
            parameterType: 'Query String Parameter',
            parameters: [{
                name: 'formId',
                type: 'uid',
                required: true,
            }],
            description: 'Penceredeki form nesnesinin validasyonunu sağlar.',
            example: "SX.formIsValid('formId')",
        }, {
            id: 'getDxDataGridInstance',
            type: "sync",
            parameterType: 'Query String Parameter',
            parameters: [{
                name: 'elementId',
                type: 'uid',
                required: true,
            }],
            description: 'Penceredeki devexpress data grid nesnesine ulaşmanızı sağlar.',
            example: `var gridInstance = SX.getDxDataGridInstance("listDxable");
        gridInstance.columnOption("command:edit", "width", 1000);
        gridInstance.refresh()
        `,
        }, {
            id: 'getBlobImageUrl',
            type: "async",
            parameterType: 'Query String Parameter',
            parameters: [{
                name: 'documentPublicId',
                type: 'uid',
                required: true,
            }],
            description: 'Sistemdeki resimi blob oralarak alabiliriz. Buradan alınan blob img src kısmına verilince resim önizlenir.',
            example: `SX.getBlobImageUrl("GUID").then((r) => {}) `,
        }, {
            id: 'getEventListeners',
            type: "void",
            parameterType: '',
            parameters: [],
            description: 'Sisteme atadığınız dinleyicilere erişebilirsiniz.',
            example: `SX.getEventListeners();`,
        }, {
            id: 'removeEventListeners',
            type: "void",
            parameterType: '',
            parameters: [],
            description: 'Sisteme atadığınız dinleyicileri siler.',
            example: `SX.removeEventListeners();`,
        }, {
            id: 'addEventListener',
            type: "void",
            parameterType: 'Query String Parameter',
            parameters: [{
                name: 'eventName',
                type: 'String',
                required: true
            }, {
                name: 'functionBody',
                type: 'Function',
                required: true
            }],
            description: 'Sisteme dinleyici atayabilirsiniz.',
            example: `var createdEventName = "SXGRID_CONTENT_READY";
        var pagingEvent = "SXGRID_ON_PAGING";
    
        function x(e) {
            gridCheckbox();
            addMultipleUpdateButton();
        }
        function y(e) {
            alert()
        }
    
        SX.addEventListener(createdEventName,
            x);
        SX.addEventListener(pagingEvent,
            y)`,
        }, {
            id: 'prepareDataGrid',
            type: "void",
            parameterType: 'Query String Parameter',
            parameters: [{
                name: 'selector',
                type: '.ElementClass Or #ElementId',
                required: true
            }, {
                name: 'columns',
                type: 'List<String>',
                required: true
            }, {
                name: 'rows',
                type: 'List<String>',
                required: false
            }, {
                name: 'summaries',
                type: 'Object',
                required: false
            }, {
                name: 'settings',
                type: 'Object',
                required: false
            }],
            description: 'Belirleyeceğiniz pencere elemanı içerisine devexpress data grid çizebilirsiniz. Toplam ve ayarlar için https://js.devexpress.com/Vue/Documentation/Guide/UI_Components/DataGrid/Getting_Started_with_DataGrid/ adresini ziyaret edebilirsiniz.',
            example: `SX.prepareDataGrid(".grid-area", ["name"], [{name:'SetXRM'}], {}, {
            width: "50%"
        })`,
        },
        {
            id: 'select2ResetValue',
            type: "void",
            parameterType: 'Query String Parameter',
            parameters: [{
                name: 'select2',
                type: '.ElementClass Or #ElementId',
                required: true
            }],
            description: 'Select2 nesnesinin değerini sıfırlar.',
            example: `SX.select2ResetValue($(".select2"))`,
        }, {
            id: 'select2SetValue',
            type: "void",
            parameterType: 'Query String Parameter',
            parameters: [{
                    name: 'select2',
                    type: '.ElementClass Or #ElementId',
                    required: true
                }, {
                    name: 'ids',
                    type: 'ValueString|ValueString2',
                    required: true
                },
                {
                    name: 'texts',
                    type: 'TextString|TextString2',
                    required: true
                }, {
                    name: 'isMultiple',
                    type: 'Boolean',
                    required: false
                }
            ],
            description: 'Select2 nesnesinin değerini atayabilirsiniz.',
            example: `SX.select2SetValue($(".select2"), "1|2", "a|b", true)`,
        }, {
            id: 'prepareSelect2WithData',
            type: "void",
            parameterType: 'Query String Parameter',
            parameters: [{
                    name: 'select2',
                    type: '.ElementClass Or #ElementId',
                    required: true
                }, {
                    name: 'data',
                    type: 'Array',
                    required: true
                },
                {
                    name: 'isMultiple',
                    type: 'Boolean',
                    required: false
                }, {
                    name: 'placeHolder',
                    type: 'String',
                    required: false
                }, {
                    name: 'dropDownParentSelectorId',
                    type: 'WindowModalElement',
                    required: false
                },
            ],
            description: 'Select2 nesnesini belirleyeceğiniz elemanlarla oluşturabilirsiniz.',
            example: `SX.prepareSelect2WithData($(".select2"), [{id:'1', text:'a'}], false, $("#xModal"))`,
        }, {
            id: 'prepareDatePicker',
            type: "void",
            parameterType: 'Query String Parameter',
            parameters: [{
                    name: 'selector',
                    type: '#ElementId',
                    required: true
                }, {
                    name: 'type',
                    type: 'String',
                    required: true
                },
                {
                    name: 'isNotEditable',
                    type: 'Boolean',
                    required: false
                }, {
                    name: 'isInline',
                    type: 'Boolean',
                    required: false
                }
            ],
            description: 'Devexpress tarih (date,datetime,time) kutusu oluşturabilirsiniz.',
            example: `SX.prepareDatePicker('#datetimepicker', 'datetime')`,
        }, {
            id: 'prepareSelect2',
            type: "void",
            parameterType: 'Query String Parameter',
            parameters: [{
                    name: 'select2',
                    type: '.ElementClass Or #ElementId',
                    required: true
                }, {
                    name: 'searchUrl',
                    type: 'String',
                    required: true
                },
                {
                    name: 'parameterObjects',
                    type: 'Object',
                    required: false
                }, {
                    name: 'excludeId',
                    type: 'String',
                    required: false
                }, {
                    name: 'isMultiple',
                    type: 'Boolean',
                    required: false
                }, {
                    name: 'maximumSelectionSize',
                    type: 'Number',
                    required: false
                },
                {
                    name: 'minimumInputLength',
                    type: 'Number',
                    required: false
                },
                {
                    name: 'milliSeconds',
                    type: 'Number',
                    required: false
                },
                {
                    name: 'placeHolder',
                    type: 'String',
                    required: false
                },
                {
                    name: 'dropDownParentSelectorId',
                    type: 'WindowModalElement',
                    required: false
                },
            ],
            description: 'Select2 nesnesini oluşturabilirsiniz.',
            example: `SX.prepareSelect2($(".select2"), "https://prodapigateway.setxrm.com/LookupFieldValues, {})`,
        },
        {
            id: 'prepareNumberInput',
            type: "void",
            parameterType: 'Query String Parameter',
            parameters: [{
                    name: 'selector',
                    type: '.ElementClass Or #ElementId',
                    required: true
                }, {
                    name: 'groupSeparator',
                    type: 'String',
                    required: true
                },
                {
                    name: 'radixPoint',
                    type: 'Boolean',
                    required: true
                }, {
                    name: 'isThousandSeparator',
                    type: 'String',
                    required: true
                }, {
                    name: 'decimalPlaces',
                    type: 'Number',
                    required: true
                }, {
                    name: 'value',
                    type: 'Number',
                    required: false
                },
            ],
            description: 'Sayı girişi kutusu oluşturabilirsiniz.',
            example: `SX.prepareNumber('.number-input', '.', ",", true, 3, 1111)`,
        },
        {
            id: 'preparePhoneInput',
            type: "void",
            parameterType: 'Query String Parameter',
            parameters: [{
                name: 'selector',
                type: '.ElementClass Or #ElementId',
                required: true
            }, {
                name: 'value',
                type: 'String',
                required: false
            }, ],
            description: 'Telefon numarası kutusu oluşturabilirsiniz.',
            example: `SX.preparePhoneNumber('phone', '+9055431233223')`,
        },
        {
            id: 'createNotification',
            type: "void",
            parameterType: 'Query String Parameter',
            parameters: [{
                name: 'type',
                type: 'String (success|warning|danger|dark|primary|info)',
                required: true
            }, {
                name: 'message',
                type: 'String',
                required: true
            }, {
                name: 'position',
                type: 'String (top-left|top-right*|bottom-left|bottom-right|top-center|bottom-center)',
                required: false
            }, {
                name: 'showIcon',
                type: 'Boolean (true*|false)',
                required: false
            }, {
                name: 'transition',
                type: 'String (bounce|zoom*|slide)',
                required: false
            }],
            description: 'Anlık bildirim oluşturabilirsiniz.',
            example: `SX.createNotification("dark", "Example Content", "top-center", false, "slide")`,
        }
    ];

var select2SystemSeparator = process.env.VUE_APP_SYSTEM_SEPARATOR,
    select2DefaultPageSize = parseInt(process.env.VUE_APP_SELECT2_PAGE_SIZE),
    activeLanguage = localStorage.getItem('activeLang'),
    localizeObj = {
        loader: {
            message: i18n.global.t('BaseModelFields.PleaseWait'),
        },
        select2: {
            placeHolder: i18n.global.t('BaseModelFields.Choose'),
        },
        datePicker: {
            placeHolder: i18n.global.t('BaseModelFields.ChooseDate')
        },
        dateTimePicker: {
            placeHolder: i18n.global.t('BaseModelFields.ChooseDateAndTime')
        },
        timePicker: {
            placeHolder: i18n.global.t('BaseModelFields.ChooseTime')
        }
    },
    windowEventFunctions = [];

function getSelect2LocaleKeys(lang) {
    if (lang == "tr") {
        return {
            errorLoading: function () {
                return "Sonuç yüklenemedi";
            },
            inputTooLong: function (args) {
                var overChars = args.input.length - args.maximum;
                var message = overChars + " karakter daha girmelisiniz";
                return message;
            },
            inputTooShort: function (args) {
                var remainingChars = args.minimum - args.input.length;
                var message =
                    "En az " + remainingChars + " karakter daha girmelisiniz";
                return message;
            },
            loadingMore: function () {
                return "Daha fazla…";
            },
            maximumSelected: function (args) {
                var message = "Sadece " + args.maximum + " seçim yapabilirsiniz";
                return message;
            },
            noResults: function () {
                return "Sonuç bulunamadı";
            },
            searching: function () {
                return "Aranıyor…";
            },
            removeAllItems: function () {
                return "Tüm öğeleri kaldır";
            },
            removeItem: function () {
                return "Bu öğeyi kaldır";
            },
            search: function () {
                return "Ara";
            },
        };
    }
    return {};
}

function formItemNewChangeEvent(fieldPublicIds) {
    if (!fieldPublicIds || !Array.isArray(fieldPublicIds)) return null;

    //# !! loop risk
    fieldPublicIds.forEach((fieldPublicId) => {
        var formItem = document.getElementById(fieldPublicId),
            event = new Event("change");
        formItem.dispatchEvent(event);
    });
}

function isSelectorUniqueId(selector) {
    if (String.isNullOrWhiteSpace(selector)) return false;
    return selector.charAt(0) === "#";
}

function forceFormValidation($form) {
    var valid = true;
    formInvalidClasses.forEach((element) => {
        if ($form.find(element).length > 0) {
            valid = false;
        }
    });
    return valid;
}

export class SX {
    static preparePhoneInput(selector, value) {
        if (!isSelectorUniqueId(selector)) {
            console.error("SX.preparePhoneInput - Selector parameter does not comply with the unique id rule or parameters are not valid. Please check.");
            return;
        }

        const phoneSelector = document.getElementById(selector);
        var iti = intlTelInput(phoneSelector, {
            nationalMode: false,
            preferredCountries: ["tr"],
            placeholderNumberType: "MOBILE",
            customPlaceholder: function (
                selectedCountryPlaceholder,
                selectedCountryData
            ) {
                var placeholder = intlTelInputUtils.getExampleNumber(
                    selectedCountryData.iso2,
                    this.nationalMode,
                    intlTelInputUtils.numberType[this.placeholderNumberType]
                );
                if (String.isNullOrWhiteSpace(placeholder)) return;

                var im = new Inputmask(
                    placeholder.replace(new RegExp("\\d", "gm"), "9")
                );
                im.mask(phoneSelector);

                return placeholder;
            },
            utilsScript: "/extras/plugins/intl-tel-input/utils.js",
        });

        if (!String.isNullOrWhiteSpace(value)) {
            iti.setNumber(value);
        }

        phoneSelector.addEventListener("open:countrydropdown", function (e) {
            var parentRelativeDiv = $(e.target).parents(".position-relative");
            parentRelativeDiv.css("z-index", "103");
        });

        phoneSelector.addEventListener("close:countrydropdown", function (e) {
            var parentRelativeDiv = $(e.target).parents(".position-relative");
            parentRelativeDiv.removeAttr("style");
        });
    }
    static prepareNumberInput(selector, groupSeparator, radixPoint, isThousandSeparator, decimalPlaces, value) {
        if (String.isNullOrWhiteSpace(selector) || String.isNullOrWhiteSpace(groupSeparator) || String.isNullOrWhiteSpace(radixPoint) || String.isNullOrWhiteSpace(isThousandSeparator) || String.isNullOrWhiteSpace(decimalPlaces)) {
            console.error("SX.prepareNumberInput function invalid or nullable parameters. Please check.");
            return;
        }

        //configuration mask
        var im = new Inputmask({
            alias: "decimal",
            groupSeparator: groupSeparator,
            radixPoint: radixPoint,
            autoGroup: isThousandSeparator,
            digits: decimalPlaces,
            clearMaskOnLostFocus: true,
            allowMinus: true,
            radixFocus: true,
            positionCaretOnClick: "none",
            autoUnmask: true,
        });
        im.mask(selector);

        if (!String.isNullOrWhiteSpace(value)) {
            $(selector).val(value);
        }
    }
    static prepareSelect2(selector, searchUrl, parameterObjects, excludeId, isMultiple, maximumSelectionSize, minimumInputLength, milliSeconds, placeHolder, dropDownParentSelectorId) {
        if (String.isNullOrWhiteSpace(searchUrl) || !isSelectorUniqueId(selector)) {
            console.error("SX.prepareSelect2 - Selector parameter does not comply with the unique id rule or parameters are not valid. Please check.");
            return;
        }

        if (typeof (parameterObjects) === 'undefined' || parameterObjects == null) parameterObjects = {
            pageSize: select2DefaultPageSize
        };

        if (typeof (excludeId) === 'undefined') excludeId = null;
        if (typeof (isMultiple) === 'undefined') isMultiple = false;
        if (typeof (maximumSelectionSize) === 'undefined') maximumSelectionSize = 99;
        if (typeof (minimumInputLength) === 'undefined') minimumInputLength = 0;
        if (typeof (parameterObjects.pageSize) === 'undefined') parameterObjects.pageSize = select2DefaultPageSize;
        if (typeof (milliSeconds) === 'undefined') milliSeconds = 250;
        if (typeof (placeHolder) === 'undefined') placeHolder = localizeObj.select2.placeHolder;

        var isLookupFieldValues = searchUrl.includes("LookupFieldValues");
        $(selector).select2({
            language: getSelect2LocaleKeys(localStorage.getItem('activeLang')),
            maximumSelectionSize: maximumSelectionSize,
            minimumInputLength: minimumInputLength,
            placeholder: placeHolder,
            allowClear: true,
            theme: "bootstrap-5",
            multiple: isMultiple,
            dropdownParent: !String.isNullOrWhiteSpace(dropDownParentSelectorId) ? $(`#${dropDownParentSelectorId}`) : null,
            separator: select2SystemSeparator,
            ajax: {
                url: searchUrl,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: String.format("Bearer {0}", localStorage.getItem('token')),
                },
                dataType: 'json',
                delay: milliSeconds,
                data: function (params) {
                    var getObject = parameterObjects;

                    getObject.pageSize = parameterObjects.pageSize;
                    getObject.pageNumber = params.page || 1;

                    if (!String.isNullOrWhiteSpace(params.term)) {
                        getObject.q = params.term;
                    } else {
                        getObject.q = "";
                    }

                    return getObject;
                },
                processResults: function (data, params) {
                    var more = data.hasNextPage && data.items.length > 0;
                    var result = [];
                    $.each(data.items, function (key, value) {
                        if (value.key != excludeId && value.value != excludeId) {
                            result.push({
                                "id": value.key,
                                "text": value.value
                            });
                        }
                    });


                    //for lookup field values
                    if (isLookupFieldValues) {
                        if (data.pageNumber === 0) {
                            data.pageNumber = params.page || 1;

                            if (data.items.length > 0) {
                                more = true;
                            }
                        }

                        if (data.items.length < select2DefaultPageSize) {
                            more = false;
                        }
                    }

                    return {
                        results: result,
                        pagination: {
                            more: more
                        }
                    };
                },
                transport: function (params, success, failure) {
                    appAxios.get(this.url, {
                        params: this.data
                    }).then(function (response) {
                        success(response.data);
                    }).catch(function (error) {
                        failure(error);
                    });
                },
            },
            escapeMarkup: function (m) {
                return m;
            },
            templateResult: function (r) {
                return Object.isHTML(r.text) && isLookupFieldValues ? $(r.text) : r.text;
            },
            templateSelection: function (s) {
                return s.text;
            },
        }).on("select2:select",
            function (e) {
                formItemNewChangeEvent([selector.replace("#", "")]);
            }).on("select2:clear", function () {
            SX.select2ResetValue($(selector));
            formItemNewChangeEvent([selector.replace("#", "")]);
        });
    }
    static prepareSelect2WithData(selector, data, isMultiple, placeHolder, dropDownParentSelectorId) {
        if (!Array.isArray(data) || !isSelectorUniqueId(selector)) {
            console.error("SX.prepareSelect2WithData - Selector parameter does not comply with the unique id rule or parameters are not valid. Please check.");
            return;
        }

        if (typeof (isMultiple) === 'undefined') isMultiple = false;
        if (typeof (placeHolder) === 'undefined') placeHolder = localizeObj.select2.placeHolder;

        $(selector).select2({
            language: getSelect2LocaleKeys(localStorage.getItem('activeLang')),
            allowClear: true,
            placeholder: placeHolder,
            theme: "bootstrap-5",
            dropdownParent: !String.isNullOrWhiteSpace(dropDownParentSelectorId) ? $(`#${dropDownParentSelectorId}`) : null,
            multiple: isMultiple,
            separator: select2SystemSeparator,
            data: data
        }).on("select2:select",
            function (e) {
                formItemNewChangeEvent([selector.replace("#", "")]);
            }).on("select2:clear", function () {
            SX.select2ResetValue($(selector));
            formItemNewChangeEvent([selector.replace("#", "")]);
        });
    }
    static select2SetValue(select2, ids, texts, isMultiple) {
        if (!select2 || String.isNullOrWhiteSpace(ids) || String.isNullOrWhiteSpace(texts)) {
            console.error("SX.select2SetValue function invalid or nullable parameters. Please check.");
            return;
        }

        if (typeof (isMultiple) === 'undefined') isMultiple = false;

        var options = select2.find("option");
        if (options.length > 0) options.remove();

        if (
            isMultiple
        ) {
            ids = ids.split(select2SystemSeparator);
            texts = texts.split(select2SystemSeparator);

            ids.forEach((id, i) => {
                select2.append(
                    $(`<option selected value="${id}">${texts[i]}</option>`)
                );
            });
        } else {
            if (ids.includes(select2SystemSeparator))
                ids = ids.split(select2SystemSeparator)[0];

            if (texts.includes(select2SystemSeparator))
                texts = texts.split(select2SystemSeparator)[0];

            select2.append($(`<option selected value="${ids}">${texts}</option>`));
        }

        select2.trigger("change");

        //calculating relation elements
        var element = document.getElementById(select2.attr('id'));
        element.dispatchEvent(new UIEvent("select", {
            bubbles: true,
            cancelable: true,
        }))
    }
    static select2ResetValue(select2) {
        if (!select2) {
            console.error("SX.select2ResetValue function invalid or nullable parameters. Please check.");
            return;
        }

        var options = select2.find("option");
        if (options.length > 0) options.remove();

        select2.trigger("change");

        //calculating relation elements
        var element = document.getElementById(select2.attr('id'));
        element.dispatchEvent(new UIEvent("select", {
            bubbles: true,
            cancelable: true,
        }));
    }
    static prepareDatePicker(selector, type, isNotEditable, isInline, formatLocaleCode) {
        if (String.isNullOrWhiteSpace(type) || !isSelectorUniqueId(selector)) {
            console.error("SX.prepareDatePicker - Selector parameter does not comply with the unique id rule or parameters are not valid. Please check.");
            return;
        }

        if (typeof (isNotEditable) === 'undefined') isNotEditable = false;
        if (typeof (isInline) === 'undefined') isInline = false;
        if (typeof (formatLocaleCode) === 'undefined') formatLocaleCode = localStorage.getItem('activeLang');

        var formatDate, formatDateTime, formatTime;
        switch (formatLocaleCode) {
            case 'en':
            case 'en-US':
                formatDate = 'M/d/yyyy';
                formatDateTime = 'M/d/yyyy hh:mm a';
                formatTime = 'hh:mm a';
                break;
            case 'tr':
            case 'tr-TR':
                formatDate = 'dd.MM.yyyy';
                formatDateTime = 'dd.MM.yyyy HH:mm';
                formatTime = 'HH:mm';
                break;
            default:
                formatDate = 'dd.MM.yyyy';
                formatDateTime = 'dd.MM.yyyy HH:mm';
                formatTime = 'HH:mm';
        }

        switch (type.toLowerCase()) {
            case 'date':
                new dxDateBox(selector, {
                    opened: false,
                    type: "date",
                    visible: true,
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    allowClear: true,
                    disabled: isNotEditable,
                    displayFormat: formatDate,
                    placeholder: localizeObj.datePicker.placeHolder,
                    onValueChanged: function (e) {
                        formItemNewChangeEvent([selector.replace("#", "")])
                    }
                });
                break;
            case 'datetime':
                new dxDateBox(selector, {
                    opened: false,
                    type: "datetime",
                    visible: true,
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    allowClear: true,
                    disabled: isNotEditable,
                    displayFormat: formatDateTime,
                    placeholder: localizeObj.dateTimePicker.placeHolder,
                    onValueChanged: function (e) {
                        formItemNewChangeEvent([selector.replace("#", "")])
                    }
                });
                break;
            case 'time':
                new dxDateBox(selector, {
                    opened: false,
                    type: "time",
                    visible: true,
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    allowClear: true,
                    disabled: isNotEditable,
                    displayFormat: formatTime,
                    placeholder: localizeObj.timePicker.placeHolder,
                    onValueChanged: function (e) {
                        formItemNewChangeEvent([selector.replace("#", "")])
                    }
                });
                break;
            default:
                console.error("Invalid type. Types: datetime, date, time. Please check.");
                break;
        }
    }
    static prepareDataGrid(selector, columns, rows, summaries = {}, settings = {}) {
        if (String.isNullOrWhiteSpace(selector) || !Array.isArray(columns) || !Array.isArray(rows)) {
            console.error("SX.prepareDataGrid function invalid or nullable parameters. Please check.");
            return;
        }

        //set default values
        dxDataGridSettings.forEach(defaultSetting => {
            var isValueAvailable = settings[defaultSetting.name];
            if (!isValueAvailable) {
                settings[defaultSetting.name] = defaultSetting.value;
            }
        });

        var element = $(selector),
            elementAttrs = settings.elementAttr,
            elementAttrId = settings.elementAttr.id,
            id = String.isNullOrWhiteSpace(elementAttrId) ? String.newGuid() : elementAttrId,
            newDivId = "dx-grid-parent-" + id;

        elementAttrs.id = id;

        element.html($("<div/>", {
            class: "dx-viewport",
            id: newDivId
        }).append($("<div/>")));

        new dxDataGrid(element.find("div:last"), {
            elementAttr: elementAttrs,
            dataSource: rows,
            onCellPrepared: settings.onCellPrepared,
            onRowPrepared: settings.onRowPrepared,
            keyExpr: settings.keyExpr,
            allowColumnReordering: settings.allowColumnReordering,
            allowColumnResizing: settings.allowColumnResizing,
            width: settings.width,
            showBorders: settings.showBorders,
            rtlEnabled: settings.rtlEnabled,
            wordWrapEnabled: settings.wordWrapEnabled,
            rowAlternationEnabled: settings.rowAlternationEnabled,
            columnAutoWidth: settings.columnAutoWidth,
            columns: columns,
            showRowLines: settings.showRowLines,
            visible: settings.isVisible,
            hoverStateEnabled: settings.hoverStateEnabled,
            focusedRowEnabled: settings.focusedRowEnabled,
            summary: summaries ? summaries : {},
            columnChooser: {
                enabled: true,
                allowSearch: true
            },
            searchPanel: {
                enabled: true,
                visible: true,
            },
            selection: {
                mode: settings.selectionMode,
            },
            sorting: {
                mode: settings.sortingMode
            },
            filterRow: {
                visible: settings.filterRowIsVisible,
            },
            groupPanel: {
                visible: settings.groupPanelIsVisible,
            },
            toolbar: {
                disabled: settings.headerItemsIsDisabled,
                items: settings.headerItems,
                visible: settings.headerIsVisible
            },
            pager: {
                visible: settings.pagerIsVisible,
                showPageSizeSelector: true,
                allowedPageSizes: [10, 20, 50, 100, 500],
            },
            onSelectionChanged: settings.onSelectionChanged
        });
    }
    static getEventListeners() {
        return windowEventFunctions;
    }
    static addEventListener(eventName, functionBody) {
        if (!availableListenerTypes.includes(eventName) || String.isNullOrWhiteSpace(eventName) || String.isNullOrWhiteSpace(functionBody)) {
            console.error(`SX.addEventListener function invalid or nullable parameters. Available Events: ${availableListenerTypes.join(', ')}  Please check.`);
            return;
        }

        windowEventFunctions.push({
            id: String.newGuid(),
            name: eventName,
            function: functionBody
        });

        window.addEventListener(eventName, functionBody);
    }
    static removeEventListeners() {
        if (!windowEventFunctions || windowEventFunctions.length == 0) return;

        windowEventFunctions.forEach(eventObj => {
            window.removeEventListener(eventObj.name, eventObj.function);
        });

        windowEventFunctions = [];
    }
    static getBlobImageUrl(documentPublicId) {
        if (String.isNullOrWhiteSpace(documentPublicId)) {
            console.error("SX.getBlobImageUrl function invalid or nullable parameters. Please check.");
            return;
        }

        return new Promise((resolve) => {
            appAxios
                .get(
                    String.format("/rws-DocumentController-Get/{0}", documentPublicId), {
                        responseType: "blob",
                    }
                )
                .then((response) => {
                    resolve(URL.createObjectURL(response.data));
                });
        });

    }
    static getDxDataGridInstance(elementId) {
        if (String.isNullOrWhiteSpace(elementId)) {
            console.error("SX.getDxDataGridInstance function invalid or nullable parameters. Please check.");
            return;
        }

        let gridElement = document.getElementById(elementId);
        if (!gridElement) {
            console.error("SX.getDxDataGridInstance function - grid not found. Please check.");
            return;
        }

        return dxDataGrid.getInstance(gridElement);
    }
    static getCkEditorInstance(elementId) {
        if (String.isNullOrWhiteSpace(elementId)) {
            console.error("SX.getCkEditorInstance function invalid or nullable parameters. Please check.");
            return;
        }

        var ckeditors = window.CKEDITOR.instances;
        if (!ckeditors) return null;

        return ckeditors[elementId];
    }
    static formIsValid(formId) {
        if (String.isNullOrWhiteSpace(formId)) {
            console.error("SX.formIsValid function invalid or nullable parameters. Please check.");
            return false;
        }

        var form = $("form#" + formId);
        if (!form) {
            console.error("SX.formIsValid function - form not found. Please check.");
            return false;
        }
        form.addClass("was-validated");
        if (!form[0].checkValidity() || !forceFormValidation(form)) {
            return false;
        }

        return true;
    }
    static async getRecord(model) {
        if (Array.isArray(model) || String.isNullOrWhiteSpace(model)) {
            console.error("SX.getRecord - Parameters are not valid. Please check.");
            return;
        }

        var instance = appAxios;
        return new Promise((resolve) => {
            instance.post("/BaseApi-GetRecord", model).then((response) => {
                resolve(response.data);
            });
        });
    }
    static async getRecords(model) {
        if (Array.isArray(model) || String.isNullOrWhiteSpace(model)) {
            console.error("SX.getRecords - Parameters are not valid. Please check.");
            return;
        }

        var instance = appAxios;
        return new Promise((resolve) => {
            instance.post("/BaseApi-GetRecords", model).then((response) => {
                resolve(response.data);
            });
        });
    }
    static async getRelationalRecords(model, isReturnCount = false) {
        if (Array.isArray(model) || String.isNullOrWhiteSpace(model)) {
            console.error("SX.getRelationalRecords - Parameters are not valid. Please check.");
            return;
        }

        var instance = appAxios;
        return new Promise((resolve) => {
            instance.post("/BaseApi-GetRelationalRecords", model).then((response) => {
                var result = response.data;
                if (isReturnCount) {
                    resolve(result.records && result.records.length);
                } else resolve(result);
            });
        });
    }
    static async createRecord(model) {
        if (Array.isArray(model) || String.isNullOrWhiteSpace(model)) {
            console.error("SX.createRecord - Parameters are not valid. Please check.");
            return;
        }

        var instance = appAxios;
        return new Promise((resolve) => {
            instance.post("/BaseApi-CreateRecord", model).then((response) => {
                resolve(response.data);
            });
        });
    }
    static async updateRecord(model) {
        if (Array.isArray(model) || String.isNullOrWhiteSpace(model)) {
            console.error("SX.updateRecord - Parameters are not valid. Please check.");
            return;
        }

        var instance = appAxios;
        return new Promise((resolve) => {
            instance.post("/BaseApi-UpdateRecord", model).then((response) => {
                resolve(response.data);
            });
        });
    }
    static async deleteRecord(customObjectKey, recordPublicId) {
        if (String.isNullOrWhiteSpace(customObjectKey) || String.isNullOrWhiteSpace(recordPublicId)) {
            console.error("SX.deleteRecord - Parameters are not valid. Please check.");
            return;
        }

        var instance = appAxios;
        return new Promise((resolve) => {
            instance.delete(`/BaseApi-DeleteRecord?customObjectKey=${customObjectKey}&recordPublicId=${recordPublicId}`).then((response) => {
                resolve(response.data);
            });
        });
    }
    static async getRecordsCount(model) {
        if (Array.isArray(model) || String.isNullOrWhiteSpace(model)) {
            console.error("SX.GetRecordsCount - Parameters are not valid. Please check.");
            return;
        }

        var instance = appAxios;
        return new Promise((resolve) => {
            instance.post("/BaseApi-GetRecordsCount", model).then((response) => {
                resolve(response.data);
            });
        });
    }
    static createNotification(type, message, position = "top-right", showIcon = true, transition = "zoom") {
        if (String.isNullOrWhiteSpace(type) || String.isNullOrWhiteSpace(message)) {
            console.error("SX.createNotification - Parameters are not valid. Please check.");
            return;
        }

        createToast(
            message, {
                showIcon: showIcon,
                position: position,
                type: type,
                transition: transition,
            }
        );
    }
    static getFunctionInformations(methodName) {
        if (String.isNullOrWhiteSpace(methodName)) {
            console.error("SX.getMethodInformations - Parameter is not valid. Please check.");
            return;
        }

        var method = sxFunctionsInformations.find(f => f.id == methodName);
        if (!method) {
            console.error("SX.getMethodInformations - Method information not found. Please check.");
            return;
        }

        console.table([{
            Type: method.type,
            Parameters: method.parameters.map(function (m) {
                return `${m.name}(${m.type})`
            }).join(', '),
            RequiredParameters: method.parameters.filter(f => f.required).map(m => m.name).join(", "),
            Description: method.description,
            Example: method.example
        }], ["Type", "Parameters", "RequiredParameters", "Description", "Example"]);
    }
    static createPageFrame(selector, src, style, settings = {}) {
        if (String.isNullOrWhiteSpace(selector) || String.isNullOrWhiteSpace(src)) {
            console.error("SX.createPageFrame function invalid or nullable parameters. Please check.");
            return;
        }

        //set default values
        sxIframeDefaultSettings.forEach(defaultSetting => {
            var name = defaultSetting.name,
                defaultId = `iframe_${String.newGuid()}`,
                isId = name === "id",
                isValueAvailable = settings[name];
            if (String.isNullOrWhiteSpace(isValueAvailable)) {
                settings[name] = isId ? defaultId : defaultSetting.value;
            }
        });

        var loadingBarId = `sx-iframe-loading-bar-${String.newGuid()}`
        selector.append(`<div id="${loadingBarId}" class="mt-3 bg-light border p-3 w-100"><div class="d-flex align-items-center justify-content-center h-100 w-100"><div class="spinner-border" style="width: 2.5rem; height: 2.5rem;" role="status">
        <span class="visually-hidden"></span>
      </div></div><b class="d-flex align-items-center pt-3 fs-5 mb-0 justify-content-center">${localizeObj.loader.message}</b></div>`)
        selector.append(
            `<iframe src="${src}" class="w-100 sx-iframe hidden ${settings.classes}" id="${settings.id}" frameborder="0" style="${style}"></iframe>`
        );

        var loadingBar = $(`#${loadingBarId}`);
        $(`#${settings.id}`).on("load", function () {
            var iframe = $(`#${settings.id}`),
                iframeContent = iframe.contents();

            loadingBar.remove();
            iframe.removeClass("hidden");

            Object.IframeSettings(settings.id);

            if (!settings.isToolbarShow) {
                iframeContent
                    .find("#sx_toolbar")
                    .remove();
            }
        });
    }
}

//export class
window.SX = SX;