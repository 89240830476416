<template>
  <AlertBox type="warning" :isModal="true" :messages="errors" />
  <div>
    <div
      class="p-12 bg-gray-100 border border-gray-300"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        type="file"
        :id="String.format('fileInputArea_{0}', field.publicId)"
        :disabled="
          element.isDisabled ||
          (this.$route.meta.isEditPage && !this.field.isEditable)
        "
        :required="this.element.isRequired || this.field.isRequired"
        @change="onFileSelected"
        ref="fileInput"
        class="hidden"
        :accept="acceptedFileType"
      />

      <label
        :for="String.format('fileInputArea_{0}', field.publicId)"
        class="block cursor-pointer w-100 fs-5"
      >
        <div class="text-center fw-bold">
          {{
            $t(
              "Components.FileUploadModal.FileInputDescription",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </div>
      </label>
      <div class="w-100 d-flex justify-content-center align-items-center mt-2">
        <div class="fs-6 badge badge-primary bg-primary p-2 border text-dark">
          <code>
            {{
              $t(
                "Components.FileUploadModal.AcceptedFileTypes",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
            <b>{{ acceptedFileType }}</b>
          </code>
        </div>
      </div>
      <ul
        class="list-group mt-3 list-group-numbered"
        v-if="this.selectedFiles.length"
      >
        <li class="list-group-item" v-for="file in selectedFiles" :key="file">
          {{ file.name }}
          <span class="badge badge-primary"> {{ file.size }} kb</span
          ><button
            class="btn btn-danger btn-xs float-end"
            type="button"
            @click="remove(selectedFiles.indexOf(file))"
          >
            <i class="ms-2 fas fa-trash cursor-pointer"></i>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { createToast } from "mosha-vue-toastify";
import AlertBox from "../shared/AlertBox.vue";
export default {
  name: "FileUpload",
  props: ["field", "element", "isCellEdit", "rowData"],
  components: {
    AlertBox,
  },
  data() {
    return {
      selectedFiles: [],
      notUploadedFiles: [],
      errors: [],
      buttonDisabled: false,
      isImageFile: !this.field.fieldType.includes("File"),
      acceptedFileType: this.field.fieldType.includes("File") ? "*" : "image/*",
    };
  },
  methods: {
    closeModal() {
      this.selectedFiles = [];
      this.notUploadedFiles = [];
      this.errors = [];
      this.buttonDisabled = false;
    },
    onFileSelected() {
      if (!this.field.isMultipleValue) {
        this.selectedFiles = [];
        this.selectedFiles.push(this.$refs.fileInput.files[0]);
      } else {
        this.selectedFiles = [...this.$refs.fileInput.files];
      }
      this.errors = [];
    },
    dragAndDropOnChange() {
      if (!this.field.isMultipleValue) {
        this.selectedFiles = [];
        this.selectedFiles.push(this.$refs.fileInput.files[0]);
      } else {
        this.selectedFiles = [...this.$refs.fileInput.files];
      }
    },
    remove(i) {
      this.selectedFiles.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.fileInput.files = event.dataTransfer.files;
      this.dragAndDropOnChange();
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    onUpload() {
      var self = this;
      this.buttonDisabled = true;
      this.errors = [];
      this.notUploadedFiles = [];

      if (this.selectedFiles.length == 0 && this.isCellEdit) {
        createToast(
          this.$t(
            "FieldWarnings.FileNotSelected",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return;
      }

      this.selectedFiles.forEach(function (file, i) {
        var formData = new FormData();
        formData.append(
          "CustomObjectPublicId",
          self.field.customObjectPublicId
        );
        formData.append(
          "RecordPublicId",
          self.isCellEdit
            ? self.rowData.PUBLICID
            : document.getElementById("RecordPublicId").value
        );
        formData.append("FieldPublicId", self.field.publicId);
        formData.append("Extention", "." + file.name.split(".").pop());
        formData.append("Name", file.name);
        formData.append("File", file);

        self.$appAxios
          .post("/rws-DocumentController-Upload", formData)
          .then((response) => {
            self.buttonDisabled = false;
            var result = response.data,
              fileName = file.name;
            if (result.isOk) {
              self.$emit("uploadFileAfterCallMethod", {
                Id: result.item.documentPublicId,
                Name: fileName,
              });
              if (
                i === self.selectedFiles.length - 1 &&
                self.notUploadedFiles.length === 0 &&
                !self.isCellEdit
              ) {
                $(".btn-close").trigger("click");
              }
              self.selectedFiles = self.selectedFiles.filter(function (item) {
                return item.name !== fileName;
              });
            } else {
              self.notUploadedFiles.push(fileName);
              self.errors.push(result.message);
            }
          })
          .catch(function (error) {
            //TODO:Error
            // alert(error);
            self.errors.push(error);
          });
      });
    },
  },
  mounted() {
    var fileInput = this.$refs.fileInput;
    if (this.field.isMultipleValue) {
      fileInput.setAttribute("multiple", "");
    }
  },
};
</script>
