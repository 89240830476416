<template>
  <template
    v-if="
      template.field.fieldType == 'Lookup' &&
      (template.field.isPlusButtonActive ||
        template.field.isViewingFilterButtonActive)
    "
  >
    <div class="field-external-buttons">
      <template v-if="$isMobile()">
        <button
          v-if="template.field.isPlusButtonActive"
          type="button"
          class="lookup-new-button me-2 btn btn-success btn-icon"
          @click="openNewTab"
          :data-lookupObjectPublicId="template.field.lookupObjectPublicId"
          :data-href="`#/set/new/${template.field.lookupObjectKey}`"
        >
          {{ $t("Set.New", {}, { locale: this.$store.state.activeLang }) }}
        </button>
        <a
          v-if="
            template.field.isViewingFilterButtonActive &&
            !template.field.isMultipleValue
          "
          target="_blank"
          :href="`#/set/detail/${template.field.lookupObjectKey}/${selectedId}`"
          :id="`open_detail_${selectId}`"
          class="btn btn-primary btn-icon"
          :class="{
            hidden: String.isNullOrWhiteSpace(selectedId),
          }"
        >
          <i class="fas fa-external-link-alt"></i>
        </a>
      </template>
      <template v-else>
        <a
          v-if="template.field.isPlusButtonActive"
          tabindex="1"
          class="cursor-pointer lookup-new-button"
          @focus="focusedExternalButtons"
          @click="openNewTab"
          :data-lookupObjectPublicId="template.field.lookupObjectPublicId"
          :data-href="`#/set/new/${template.field.lookupObjectKey}`"
        >
          <i class="fas fa-plus text-success"></i>
        </a>
        <a
          v-if="
            template.field.isViewingFilterButtonActive &&
            !template.field.isMultipleValue
          "
          tabindex="1"
          target="_blank"
          :href="`#/set/detail/${template.field.lookupObjectKey}/${
            String.isNullOrWhiteSpace(detailId) ? valueId : detailId
          }`"
          @focus="focusedExternalButtons"
          :id="`open_detail_${selectId}`"
          class="ms-2"
          :class="{
            hidden: String.isNullOrWhiteSpace(
              rowData[template.field.formulaName]
            ),
          }"
        >
          <i class="fas fa-external-link-alt text-primary"></i>
        </a>
      </template>
    </div>
  </template>
  <Select2
    :placeholder="placeholder"
    ref="select2"
    :id="selectId"
    :name="selectId"
    :settings="settings"
    @select="selection($event)"
  />
</template>
<script>
/* Select2 settings api document: https://select2.org/configuration/options-api */
import $ from "jquery";
import Select2 from "vue3-select2-component";
export default {
  name: "PagedSelectList",
  props: ["template", "value", "valueId", "table", "rowData"],
  components: {
    Select2,
  },
  data() {
    return {
      detailId: null,
      selectId: `${this.template.id}`,
      placeholder: this.template.field.fieldType.includes("Formula")
        ? this.$t(
            "BaseModelFields.FieldIsCalcForSystem",
            {},
            { locale: this.$store.state.activeLang }
          )
        : this.$t(
            "BaseModelFields.Choose",
            {},
            { locale: this.$store.state.activeLang }
          ),
      selectedText: "",
      selectedId: "",
      settings: {
        dropdownParent: null,
        language: this.template.parent.$root.getSelect2Locale(
          this.template.parent.$store.state.activeLang
        ),
        theme: "bootstrap-5",
        allowClear: !this.isDisabled(),
        closeOnSelect: true,
        maximumSelectionSize: 99,
        minimumInputLength: 0,
        disabled: this.isDisabled(),
        multiple: this.template.field.isMultipleValue,
        separator: this.template.parent.$systemSeparator,
        dropdownAutoWidth: false,
        ajax: this.template.parent.$root.getSelect2AjaxConfiguration(
          this.template.field,
          this.setRequestUrl()
        ),
        templateResult: function (d) {
          return Object.isHTML(d.text) ? $(d.text) : d.text;
        },
      },
    };
  },
  methods: {
    focusedExternalButtons(e) {
      $(e.target).closest("td").find(".select2-selection").focus();
    },
    openNewTab(event) {
      localStorage.removeItem("NewLookupTabData");
      localStorage.removeItem("LookupInfo");

      var $this = $(event.currentTarget),
        self = this,
        lookupTabData = "NewLookupTabData",
        gridItem = $this.closest("[data-class='grid-item']");

      if (!String.isNullOrWhiteSpace(gridItem.data("controllingpublicid"))) {
        var grid = $("#" + self.template.parent.gridId),
          parentGridItem = grid.find(
            String.format(
              "[data-id='{0}']",
              gridItem.data("controllingpublicid")
            )
          );

        if (!parentGridItem) return;

        var parentElement = parentGridItem.find(
            "#" + parentGridItem.data("editcelltemplateelementid")
          ),
          parentData = parentElement.select2("data");

        if (String.isNullOrWhiteSpace(parentElement.val())) return;

        localStorage.setItem(
          "LookupInfo",
          JSON.stringify({
            coId: parentGridItem.data("lookupobjectid"),
            rId: parentData[0].id,
            val: parentData[0].text,
            fId: "",
          })
        );
      }

      localStorage.setItem(
        lookupTabData,
        JSON.stringify({
          id: $this.data("id"),
          fieldIds: gridItem.data("lookupfieldids"),
          data: null,
          fData: {},
          isMultiple: gridItem.data("ismultiplevalue"),
        })
      );
      window.open($this.data("href"), "_blank");

      var timer = setInterval(function () {
        var newLookupTabData = localStorage.getItem(lookupTabData),
          obj = JSON.parse(newLookupTabData);

        if (obj && obj.data) {
          var pId = gridItem.data("publicid"),
            pIdSelector = "#" + pId;
          var selectedData = $(pIdSelector).select2("data");
          if (obj.isMultiple) {
            if (!selectedData) selectedData = [];
            selectedData.push(obj.data);
          } else {
            selectedData = obj.data;
          }

          var id = selectedData.id,
            text = selectedData.text;
          if (self.template.field.isMultipleValue) {
            id = selectedData
              .map(function (x) {
                return x.id;
              })
              .join(self.$systemSeparator);

            text = selectedData
              .map(function (x) {
                return x.text;
              })
              .join(self.$systemSeparator);
          }

          self.template.parent.editCellTemplateValueChangedForSelect2Components(
            self.template.field,
            self.selectId,
            text,
            id,
            self.template
          );

          self.template.parent.$root.selectPagedSelectlistSelectionForGrid(
            self.template.field,
            id,
            self.template.parent.pageAllFields,
            self.template.parent.gridId,
            self.template
          );

          self.template.parent.$root.calculateTriggerFieldForGrid(
            self.template.parent,
            self.template
          );

          if (!self.template.field.isMultipleValue) {
            var detailIcon = document.getElementById(
              "open_detail_" + self.selectId
            );
            detailIcon.classList.remove("hidden");
            detailIcon.setAttribute(
              "href",
              String.format(
                "#/set/detail/{0}/{1}",
                self.template.field.lookupObjectKey,
                id
              )
            );
          }

          localStorage.removeItem(lookupTabData);
          clearInterval(timer);
        }
      }, 1500);
    },
    selection() {
      var el = $(String.format("#{0}", this.selectId)),
        elData = el.select2("data");

      this.selectedText = elData.map((u) => u.text).join(this.$systemSeparator);
      this.selectedId = elData.map((u) => u.id).join(this.$systemSeparator);

      this.template.parent.editCellTemplateValueChangedForSelect2Components(
        this.template.field,
        this.selectId,
        this.selectedText,
        this.selectedId,
        this.template
      );

      this.template.parent.$root.selectPagedSelectlistSelectionForGrid(
        this.template.field,
        this.selectedId,
        this.template.parent.pageAllFields,
        this.template.parent.gridId,
        this.template
      );

      this.template.parent.$root.calculateTriggerFieldForGrid(
        this.template.parent,
        this.template
      );
    },
    isDisabled() {
      var isControllingItem = this.isControllingItem(),
        parentElementValue = this.getParentElementValue(),
        parentElementIsNull = isControllingItem
          ? String.isNullOrWhiteSpace(parentElementValue)
          : true,
        currentFieldFormula = this.template.field.fieldType.includes("Formula"),
        isDisabled =
          this.template.filterField.disabled ||
          this.template.field.disabled ||
          currentFieldFormula ||
          !this.template.field.isActive ||
          (!String.isNullOrWhiteSpace(
            this.rowData[this.template.parent.primaryKey]
          ) &&
            !this.template.field.isEditable);

      return (isControllingItem && parentElementIsNull) || isDisabled
        ? true
        : false;
    },
    isControllingItem() {
      return !String.isNullOrWhiteSpace(
        this.template.field.controllingPublicId
      );
    },
    getParentElementValue() {
      var isControllingItem = this.isControllingItem(),
        parentElementValue = null;

      if (
        this.table &&
        this.table.fieldPublicId == this.template.field.controllingPublicId
      ) {
        parentElementValue = this.$route.params.id;
      } else {
        var gridItem = $(
          String.format(
            "[data-class='grid-item'][data-publicid='{0}']",
            this.template.field.controllingPublicId
          )
        );
        parentElementValue = $(
          "#" + gridItem.data("editcelltemplateelementid")
        ).val();
      }

      return parentElementValue;
    },
    setRequestUrl() {
      var requestUrl = null;
      switch (this.template.field.fieldType) {
        case "Predefined":
          requestUrl = this.template.parent.$root.getFieldRequestUrl(
            "predefined",
            {
              parentId: this.template.field.predefinedPublicId,
            }
          );
          break;
        case "OrganizationalUnit":
          requestUrl = this.template.parent.$root.getFieldRequestUrl(
            "organizationalunit",
            {
              publicId: this.template.field.publicId,
              name: this.template.field.organizationalUnitName,
              filterType: this.template.field.organizationalUnitFilterType,
              groupIds: this.template.field.organizationalUnitGroupPublicIds,
              includeItself:
                this.template.field.organizationalUnitIncludeItself,
              depth: this.template.field.organizationalUnitDepth,
              isAddCurrentKeys: false,
            }
          );
          break;
        case "Lookup":
          requestUrl = this.template.parent.$root.getFieldRequestUrl("lookup", {
            coId: this.template.field.customObjectPublicId,
            fieldPublicId: this.template.field.publicId,
          });
          break;
        default:
          break;
      }

      return requestUrl;
    },
  },
  mounted() {
    var select = $("#" + this.selectId);
    if (String.isGuid(this.value) && String.isGuid(this.valueId)) {
      return;
    }

    if (!String.isNullOrWhiteSpace(this.template.field.controllingPublicId)) {
      this.template.parent.$root.select2PagedSelectlistDependencyChildTriggerForGrid(
        select.closest("tr"),
        this.template.field,
        this.template
      );
    }

    if (
      !String.isNullOrWhiteSpace(this.value) &&
      !String.isNullOrWhiteSpace(this.valueId)
    ) {
      if (!this.valueId.includes("error") && !this.value.includes("error")) {
        this.selectedText = this.value;
        this.selectedId = this.valueId;
        this.template.parent.$root.select2SetValue(
          select,
          this.template.field,
          this.valueId,
          this.value,
          true
        );

        //TODO:Sorulacak
        // if (!this.template.parent.isDxRowInlineEditing) {
        //   this.template.parent.$root.calculateTriggerFieldForGrid(
        //     this.template.parent,
        //     this.template
        //   );
        // }
      }
    }

    var publicIdValue =
      this.rowData[
        this.template.field.formulaName +
          this.template.parent.$fieldPublicIdFormulaExtension
      ];

    this.detailId = String.isNullOrWhiteSpace(publicIdValue)
      ? this.rowData[this.template.field.formulaName]
      : publicIdValue;
  },
};
</script>
