<template>
  <div
    v-if="button"
    id="buttonApproveModal"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="buttonApproveModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog sx-modal-responsive modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-capitalize">
            <i
              :class="button.icon"
              :style="{ color: button.iconColor }"
              v-if="button.iconColor"
            ></i>
            <i :class="button.icon" v-else></i>
            {{ button.name }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="alert alert-info">
            {{ button.approvalMessageContent }}
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-success btn-sm"
            @click="approve"
            data-bs-dismiss="modal"
          >
            <i class="fas fa-check"></i>
            {{
              $t(
                "BaseModelFields.Yes",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
          <button
            type="button"
            class="btn btn-danger btn-sm"
            data-bs-dismiss="modal"
          >
            <i class="fas fa-times"></i>
            {{
              $t(
                "BaseModelFields.Cancel",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ButtonApproveModal",
  props: ["button"],
  emits: ["approved"],
  data() {
    return {};
  },
  components: {},
  methods: {
    approve() {
      this.$emit("approved", this.button);
    },
  },
};
</script>
