<template>
  <div class="dx-viewport">
    <DxDateBox
      class="dx-datepicker-container"
      ref="datePicker"
      :value="value"
      :type="type"
      :displayFormat="displayFormat"
      :showClearButton="true"
      :disabled="isDisabled"
      :onValueChanged="onValueChanged"
      :openOnFieldClick="true"
      :input-attr="inputAttributes"
      :interval="String.isNullOrWhiteSpace(interval) ? 30 : interval"
      :useMaskBehavior="true"
      :applyValueMode="applyValueMode"
      :min="minDate"
      :placeholder="placeholder"
    />
  </div>
</template>
<script>
import $ from "jquery";
/* https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDateBox/Configuration/ */
import DxDateBox from "devextreme-vue/date-box";
export default {
  name: "DatePickerByType",
  props: [
    "value", //input value
    "interval", //Specifies the interval between neighboring values in the popup list in minutes
    "id", //label on click focused input
    "type", //time,date,datetime
    "isRequired", //field is required?
    "isDisabled", //field is disabled?
    "isReturnMaskedValue", // on value changed return masked value?
    "minDate", // min date value
  ],
  emits: ["valueChanged"],
  data() {
    return {
      placeholder: "",
      displayFormat: null,
      dateFormatInfo: this.$root.getDateTimeFormat("short"),
      dateTimeFormatInfo: this.$root.getDateTimeFormat(),
      inputAttributes: {
        required: this.isRequired,
        class: "form-control",
        id: this.id,
      },
      applyValueMode:
        this.type == "date" || this.type == "time" ? "instantly" : "useButtons",
    };
  },
  components: {
    DxDateBox,
  },
  created() {
    switch (this.type) {
      case "time":
        this.displayFormat = this.dateFormatInfo.time.replace("A", "a");
        this.placeholder = this.$t(
          "BaseModelFields.ChooseTime",
          {},
          { locale: this.$store.state.activeLang }
        );
        break;
      case "date":
        this.displayFormat = this.dateFormatInfo.calendar
          .replace("DD", "dd")
          .replace("D", "d");
        this.placeholder = this.$t(
          "BaseModelFields.ChooseDate",
          {},
          { locale: this.$store.state.activeLang }
        );
        break;
      case "datetime":
        this.displayFormat = this.dateTimeFormatInfo.calendar
          .replace("A", "a")
          .replace("DD", "dd")
          .replace("D", "d");
        this.placeholder = this.$t(
          "BaseModelFields.ChooseDateAndTime",
          {},
          { locale: this.$store.state.activeLang }
        );
        break;
      default:
        break;
    }
  },
  methods: {
    onValueChanged(e) {
      var value = null;
      if (!String.isNullOrWhiteSpace(e.value)) {
        value = e.component._maskValue;
        if (this.isReturnMaskedValue && !String.isNullOrWhiteSpace(this.id)) {
          value = $("#" + this.id).val();
        }
      }

      /* UI Change Event Fix */
      this.$root.formItemNewChangeEvent([this.id]);

      this.$emit("valueChanged", value);
    },
  },
};
</script>
