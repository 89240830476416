<template>
  <div
    :id="modalId"
    class="modal fade"
    :class="{ $listPageGridId: this.$route.meta.isListPage }"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-hidden="true"
  >
    <div
      class="modal-dialog sx-modal-responsive modal-dialog-centered"
      :class="{
        'modal-sm':
          selectedRecordCellField &&
          selectedRecordCellField.fieldType == 'Checkbox',
        'modal-lg':
          selectedRecordCellField &&
          (selectedRecordCellField.fieldType == 'File' ||
            selectedRecordCellField.fieldType == 'Image'),
        'modal-xl':
          selectedRecordCellField &&
          selectedRecordCellField.fieldType == 'Html',
      }"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="selectedRecordCellField">
            <i class="fas fa-edit"></i>
            {{
              String.format(
                "{0} {1}",
                selectedRecordCellField.name,
                this.$t(
                  "Set.Edit",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              )
            }}
          </h5>
          <button
            type="button"
            class="btn-close btn-cell-edit-close"
            data-bs-dismiss="modal"
            @click="closeCellEdit"
            aria-label="Close"
            v-if="!updateRequestSending"
          ></button>
        </div>
        <div class="modal-body" v-if="!isMounted">
          <form
            v-if="selectedRecordCellField"
            :id="formId"
            @submit="saveCell"
            method="post"
            action="#"
          >
            <div
              class="form-group page-item edit-cell-page-item"
              :data-istable="false"
              :data-tableid="selectedRecordCellField.tablePublicId"
              :data-fieldtype="selectedRecordCellField.fieldType"
              :data-inputtype="selectedRecordCellField.inputHtmlType"
              :data-maxlength="selectedRecordCellField.length"
              :data-decimalplaces="selectedRecordCellField.decimalPlaces"
              :data-inputmaskpattern="selectedRecordCellField.inputMaskPattern"
              :data-controllingpublicid="
                selectedRecordCellField.controllingPublicId
              "
              :data-lookupobjectid="
                selectedRecordCellField.lookupObjectPublicId
              "
              :data-lookupobjectname="selectedRecordCellField.lookupObjectName"
              :data-lookupobjecturl="selectedRecordCellField.lookupObjectKey"
              :data-lookupfieldids="
                selectedRecordCellField.lookupFieldPublicIds
              "
              :data-selecteditempublicids="
                selectedRecordCellField.defaultValuePublicIds
              "
              :data-organizationalunitname="
                selectedRecordCellField.organizationalUnitName
              "
              :data-organizationalunitfiltertype="
                selectedRecordCellField.organizationalUnitFilterType
              "
              :data-organizationalunitgrouppublicids="
                selectedRecordCellField.organizationalUnitGroupPublicIds
              "
              :data-organizationalunitdepth="
                selectedRecordCellField.organizationalUnitDepth
              "
              :data-organizationalunitincludeitself="
                selectedRecordCellField.organizationalUnitIncludeItself
              "
              :data-ismultiplevalue="selectedRecordCellField.isMultipleValue"
              :data-isradio="selectedRecordCellField.isRadio"
              :data-isrequired="selectedRecordCellField.isRequired"
              :data-isunique="selectedRecordCellField.isUnique"
              :data-isactive="selectedRecordCellField.isActive"
              :data-isdisabled="
                element.isDisabled ||
                selectedRecordCellField.fieldType.includes('Formula') ||
                selectedRecordCellField.fieldType.includes('AutoId')
              "
              :data-isclientcalculate="
                selectedRecordCellField.isClientCalculate
              "
              :data-iscalculateonclientchange="
                selectedRecordCellField.isCalculateOnClientChange
              "
              :data-ischeckunchanged="selectedRecordCellField.isCheckUnchanged"
              :data-isuniquecheckclientcalculate="
                selectedRecordCellField.isUniqueCheckClientCalculate
              "
              :data-istablerollup="selectedRecordCellField.isTableRollup"
              :data-isnoteditable="!selectedRecordCellField.isEditable"
              :data-rolluptable="selectedRecordCellField.tablePublicId"
              :data-rollupfield="selectedRecordCellField.rollupFieldPublicId"
              :data-rollupformula="selectedRecordCellField.aggregateFunction"
              :data-calculatetriggerfieldpublicids="
                selectedRecordCellField.calculateTriggerFieldPublicIds
              "
              :data-isthousandseparator="
                selectedRecordCellField.isThousandSeparator
              "
              :data-formulaname="selectedRecordCellField.formulaName"
              :data-formulatext="selectedRecordCellField.formulaText"
              :data-predefinedpublicid="
                selectedRecordCellField.predefinedPublicId
              "
              :data-predefineddependencypublicid="
                selectedRecordCellField.predefinedDependencyPublicId
              "
              :data-viewfilterpublicid="
                selectedRecordCellField.viewFilterPublicId
              "
              :data-publicid="selectedRecordCellField.publicId"
              :data-property="selectedRecordCellField.property"
            >
              <TextBox
                ref="formItem"
                :element="element"
                :field="selectedRecordCellField"
                :isCellEdit="true"
                :rowData="selectedRowData"
                :value="formItemValue(selectedRecordCellField)"
                :crudType="crudType"
                v-if="
                  selectedRecordCellField.fieldType == 'Text' ||
                  selectedRecordCellField.fieldType == 'FormulaString' ||
                  selectedRecordCellField.fieldType == 'Url' ||
                  selectedRecordCellField.fieldType == 'AutoId' ||
                  selectedRecordCellField.fieldType == 'Email'
                "
              />
              <PercentInput
                ref="formItem"
                :element="element"
                :field="selectedRecordCellField"
                :isCellEdit="true"
                :rowData="selectedRowData"
                :value="formItemValue(selectedRecordCellField)"
                :crudType="crudType"
                v-if="selectedRecordCellField.fieldType == 'Percent'"
              />
              <NumericTextBox
                ref="formItem"
                :element="element"
                :field="selectedRecordCellField"
                :isCellEdit="true"
                :rowData="selectedRowData"
                :value="formItemValue(selectedRecordCellField)"
                :crudType="crudType"
                v-if="
                  selectedRecordCellField.fieldType == 'Number' ||
                  selectedRecordCellField.fieldType == 'DoublePrecision' ||
                  selectedRecordCellField.fieldType == 'LongInteger' ||
                  selectedRecordCellField.fieldType == 'FormulaNumber' ||
                  selectedRecordCellField.fieldType == 'Rollup'
                "
              />
              <Checkbox
                ref="formItem"
                :element="element"
                :field="selectedRecordCellField"
                :isCellEdit="true"
                :rowData="selectedRowData"
                :value="formItemValue(selectedRecordCellField)"
                :crudType="crudType"
                v-if="selectedRecordCellField.fieldType == 'Checkbox'"
              />
              <TextArea
                ref="formItem"
                :element="element"
                :field="selectedRecordCellField"
                :isCellEdit="true"
                :rowData="selectedRowData"
                :value="formItemValue(selectedRecordCellField)"
                :crudType="crudType"
                v-if="
                  selectedRecordCellField.fieldType == 'TextArea' ||
                  selectedRecordCellField.fieldType == 'FormulaTextArea'
                "
              />
              <DatePicker
                ref="formItem"
                :element="element"
                :field="selectedRecordCellField"
                :isCellEdit="true"
                :rowData="selectedRowData"
                :value="formItemValue(selectedRecordCellField)"
                :crudType="crudType"
                v-if="
                  selectedRecordCellField.fieldType == 'Date' ||
                  selectedRecordCellField.fieldType == 'FormulaDate'
                "
              />
              <DateTimePicker
                ref="formItem"
                :element="element"
                :field="selectedRecordCellField"
                :isCellEdit="true"
                :rowData="selectedRowData"
                :value="formItemValue(selectedRecordCellField)"
                :crudType="crudType"
                v-if="
                  selectedRecordCellField.fieldType == 'DateTime' ||
                  selectedRecordCellField.fieldType == 'FormulaDateTime'
                "
              />
              <TimePicker
                ref="formItem"
                :element="element"
                :field="selectedRecordCellField"
                :isCellEdit="true"
                :rowData="selectedRowData"
                :value="formItemValue(selectedRecordCellField)"
                :crudType="crudType"
                v-if="selectedRecordCellField.fieldType == 'Time'"
              />
              <Spatial
                ref="formItem"
                :element="element"
                :field="selectedRecordCellField"
                :isCellEdit="true"
                :rowData="selectedRowData"
                :value="formItemValue(selectedRecordCellField)"
                :crudType="crudType"
                v-if="
                  selectedRecordCellField.fieldType == 'Spatial' ||
                  selectedRecordCellField.fieldType == 'FormulaSpatial'
                "
              />
              <SelectList
                ref="formItem"
                :element="element"
                :field="selectedRecordCellField"
                :value="formItemValue(selectedRecordCellField)"
                :valueId="formItemValueId(selectedRecordCellField)"
                :isCellEdit="true"
                :rowData="selectedRowData"
                :crudType="crudType"
                :cellEditDrowdownParent="modalId"
                v-if="
                  selectedRecordCellField.fieldType == 'SelectList' &&
                  !selectedRecordCellField.isRadio
                "
              />
              <RadioSelectList
                ref="formItem"
                :element="element"
                :field="selectedRecordCellField"
                :isCellEdit="true"
                :rowData="selectedRowData"
                :value="formItemValue(selectedRecordCellField)"
                :valueId="formItemValueId(selectedRecordCellField)"
                :crudType="crudType"
                v-else-if="
                  selectedRecordCellField.fieldType == 'SelectList' &&
                  selectedRecordCellField.isRadio
                "
              />
              <PagedSelectList
                ref="formItem"
                :element="element"
                :field="selectedRecordCellField"
                :value="formItemValue(selectedRecordCellField)"
                :valueId="formItemValueId(selectedRecordCellField)"
                :isCellEdit="true"
                :rowData="selectedRowData"
                :crudType="crudType"
                :cellEditDrowdownParent="modalId"
                v-if="
                  selectedRecordCellField.fieldType == 'Lookup' ||
                  selectedRecordCellField.fieldType == 'OrganizationalUnit' ||
                  selectedRecordCellField.fieldType ==
                    'FormulaOrganizationalUnit' ||
                  selectedRecordCellField.fieldType == 'Predefined'
                "
              />
              <FileInput
                ref="formItem"
                :element="element"
                :field="selectedRecordCellField"
                :isCellEdit="true"
                :rowData="selectedRowData"
                :value="formItemValue(selectedRecordCellField)"
                :valueId="formItemValueId(selectedRecordCellField)"
                :crudType="crudType"
                @cellFileUpload="cellFileUpload"
                @removedFile="removeFile"
                v-if="
                  selectedRecordCellField.fieldType == 'File' ||
                  selectedRecordCellField.fieldType == 'Image'
                "
              />
              <PhoneInput
                ref="formItem"
                :element="element"
                :field="selectedRecordCellField"
                :isCellEdit="true"
                :rowData="selectedRowData"
                :value="formItemValue(selectedRecordCellField)"
                :crudType="crudType"
                v-if="selectedRecordCellField.fieldType == 'Phone'"
              />
              <HtmlEditor
                ref="formItem"
                :element="element"
                :field="selectedRecordCellField"
                :isCellEdit="true"
                :rowData="selectedRowData"
                :value="formItemValue(selectedRecordCellField)"
                :crudType="crudType"
                v-if="selectedRecordCellField.fieldType == 'Html'"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer" v-if="selectedRecordCellField">
          <button
            type="button"
            class="btn btn-success btn-sm btn-cell-save"
            v-if="
              this.selectedRecordCellField.fieldType != 'File' &&
              this.selectedRecordCellField.fieldType != 'Image'
            "
            @click="saveCell"
          >
            <span>
              <i class="fas fa-save"></i>
              {{
                $t(
                  "BaseModelFields.Save",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </span>
          </button>
          <button
            type="button"
            class="btn btn-success btn-sm btn-cell-save"
            v-else
            @click="saveCell"
          >
            <span>
              <i class="fas fa-save"></i>
              {{
                $t(
                  "Components.FileUploadModal.Upload",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </span>
          </button>
          <button
            type="button"
            class="btn btn-danger btn-sm btn-cell-save-cancel"
            data-bs-dismiss="modal"
            @click="closeCellEdit"
          >
            <i class="fas fa-times"></i>
            {{
              $t(
                "BaseModelFields.Cancel",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TextBox from "@/components/set-design/form-items/TextBox.vue";
import NumericTextBox from "@/components/set-design/form-items/NumericTextBox.vue";
import Checkbox from "@/components/set-design/form-items/Checkbox.vue";
import TextArea from "@/components/set-design/form-items/TextArea.vue";
import DateTimePicker from "@/components/devexpress/dateboxes/DateTimePicker.vue";
import DatePicker from "@/components/devexpress/dateboxes/DatePicker.vue";
import TimePicker from "@/components/devexpress/dateboxes/TimePicker.vue";
import SelectList from "@/components/set-design/form-items/SelectList.vue";
import RadioSelectList from "@/components/set-design/form-items/RadioSelectList.vue";
import PagedSelectList from "@/components/set-design/form-items/PagedSelectList.vue";
import FileInput from "@/components/set-design/form-items/FileInput.vue";
import PhoneInput from "@/components/set-design/form-items/PhoneInput.vue";
import HtmlEditor from "@/components/set-design/form-items/HtmlEditor.vue";
import PercentInput from "@/components/set-design/form-items/PercentInput.vue";
import Spatial from "@/components/set-design/form-items/Spatial.vue";

import $ from "jquery";
import { createToast } from "mosha-vue-toastify";
import { showModal, hideModal } from "@/core/helpers/dom";

export default {
  name: "CellEditModal",
  props: [
    "id",
    "selectedRowData",
    "selectedRecordCellField",
    "selectedRowIndex",
    "selectedCellIndex",
    "isNewRecord",
    "fieldValues",
  ],
  emits: ["updatedCell"],
  components: {
    TextBox,
    Checkbox,
    TextArea,
    DatePicker,
    DateTimePicker,
    TimePicker,
    SelectList,
    PagedSelectList,
    RadioSelectList,
    NumericTextBox,
    FileInput,
    PhoneInput,
    HtmlEditor,
    PercentInput,
    Spatial,
  },
  data() {
    return {
      modalId: `modalEditCell_${this.id}`,
      model: {},
      isMounted: false,
      crudType: "GridCellEdit",
      element: {
        isRequired: false,
        isDisabled: false,
        isHidden: false,
        id: "",
      },
      formId: String.format("gridCellEditForm_{0}", String.newGuid()),
      editModeSubmitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "BaseModelFields.Updating",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      errors: [],
      editModeDisabledButtonClasses: ".btn-cell-save-cancel, .btn-cell-save",
      updateRequestSending: false,
    };
  },
  watch: {
    selectedRecordCellField(newValue) {
      this.element.id = this.selectedRecordCellField.publicId;
      this.isMounted = true;
      setTimeout(() => {
        this.isMounted = false;
      }, 100);
    },
    selectedRowData() {
      this.isMounted = true;
      setTimeout(() => {
        this.isMounted = false;
      }, 100);
    },
  },
  methods: {
    formItemValue(cell) {
      return this.selectedRowData[cell.formulaName];
    },
    formItemValueId(cell) {
      return this.selectedRowData[
        cell.formulaName + this.$fieldPublicIdFormulaExtension
      ];
    },
    closeCellEdit() {
      $(".sx-grid-edit-cell").removeClass("sx-grid-edit-cell");
    },
    removeFile(files) {
      this.model = {
        customObjectPublicId: this.selectedRecordCellField.customObjectPublicId,
        customObjectKey: this.$route.params.key,
        pageLayoutId: "",
        recordPublicId: this.selectedRowData.PUBLICID,
        values: [
          {
            key: this.selectedRecordCellField.formulaName,
            value: files.map((m) => m.Id).join(this.$systemSeparator),
            text: files.map((m) => m.Name).join(this.$systemSeparator),
          },
        ],
      };
      this.saveCellHttpRequest(null);
    },
    updateCellHtml(selectedCell, value, text) {
      var currentField = this.selectedRecordCellField,
        fieldBlock = selectedCell,
        innerText = text,
        self = this;

      switch (currentField.fieldType) {
        case "Lookup":
          fieldBlock.html("");
          var fieldValueId = value;
          if (String.isNullOrWhiteSpace(fieldValueId)) {
            innerText.split(this.$systemSeparator).forEach((id) => {
              var item = this.gridSelectDatas.find((f) => f.key === id);
              if (currentField.isMultipleValue) {
                fieldBlock.append(
                  "<span class='badge text-dark border border-primary me-1 mb-1 fs-7'><a target='_blank' href='#/set/detail/" +
                    currentField.lookupObjectKey +
                    "/" +
                    id +
                    "'>" +
                    item.value +
                    "</a></span>"
                );
              } else {
                fieldBlock.append(
                  "<a target='_blank' href='#/set/detail/" +
                    currentField.lookupObjectKey +
                    "/" +
                    id +
                    "'>" +
                    item.value +
                    "</a>"
                );
              }
            });
          } else {
            if (currentField.isMultipleValue) {
              var fieldValueIds = fieldValueId.split(this.$systemSeparator),
                innerTexts = innerText.split(this.$systemSeparator);
              fieldBlock.html("");
              fieldValueIds.forEach((i, element) => {
                fieldBlock.append(
                  "<a target='_blank' href='#/set/detail/" +
                    currentField.lookupObjectKey +
                    "/" +
                    element +
                    "'>" +
                    innerTexts[i] +
                    "</a>"
                );
              });
            } else {
              fieldBlock.html(
                "<a target='_blank' href='#/set/detail/" +
                  currentField.lookupObjectKey +
                  "/" +
                  fieldValueId +
                  "'>" +
                  innerText +
                  "</a>"
              );
            }
          }
          break;
        case "SelectList":
        case "OrganizationalUnit":
        case "Predefined":
          fieldBlock.html("");
          if (currentField.isMultipleValue) {
            var innerTextsSplited = innerText.split(this.$systemSeparator);
            innerTextsSplited.forEach((element) => {
              fieldBlock.append(
                "<span class='badge text-dark border border-primary me-1 mb-1 fs-7'>" +
                  element +
                  "</span>"
              );
            });
          } else {
            fieldBlock.html(text);
          }
          break;
        case "Checkbox":
          fieldBlock.html(
            Boolean.toBool(innerText.toString())
              ? "<i class='fa fa-check ms-2 text-success'></i>"
              : "<i class='fa fa-times ms-2 text-danger'></i>"
          );
          break;
        case "Html":
          var div = document.createElement("div");
          div.className = "e-grid-html-field";
          div.innerHTML = innerText;
          div.style.cssText = String.format(
            "max-height:{0}px!important;",
            this.gridRowHeight
          );

          fieldBlock.html(div);
          break;
        case "Url":
          fieldBlock.html(
            "<a target='_blank' href='" +
              innerText +
              "'><i class='fa fa-link text-primary'></i> " +
              innerText +
              "</a>"
          );
          break;
        case "Email":
          fieldBlock.html(
            "<a href='mailto:" +
              innerText +
              "'><i class='fa fa-at text-primary'></i> " +
              innerText +
              "</a>"
          );
          break;
        case "Phone":
          fieldBlock.html(
            "<a href='tel:" +
              innerText +
              "'><i class='fa fa-phone text-primary'></i> " +
              innerText +
              "</a>"
          );
          break;
        case "Image":
        case "File":
          var recordIds = value;
          if (!String.isNullOrWhiteSpace(recordIds)) {
            var formFileListId = "ffl_" + String.newGuid();
            fieldBlock.html(
              $("<div/>", {
                class: "form-file-list",
                id: formFileListId,
              }).append($("<ul/>", { class: "list-inline" }))
            );

            var ffl = fieldBlock.find("#" + formFileListId).find("ul");

            recordIds = recordIds.split(this.$systemSeparator);
            innerText = innerText.split(this.$systemSeparator);
            recordIds.forEach((element, i) => {
              if (self.$root.isDocumentViewerFile(innerText[i])) {
                ffl.append(
                  $("<li/>").append(
                    $("<span/>")
                      .append(
                        $("<a/>", {
                          // href: String.format("/document/viewer/{0}", element),
                          // target: "_blank",
                        }).text(innerText[i])
                      )
                      .append(
                        $("<span/>", {
                          class: "ms-2 cursor-pointer download-file",
                          name: innerText[i],
                          id: element,
                        }).append(
                          $("<i/>", { class: "fas fa-download text-primary" })
                        )
                      )
                  )
                );
              } else {
                ffl.append(
                  $("<li/>").append(
                    $("<span/>").append(
                      $("<span/>", {
                        class: "cursor-pointer download-file",
                        name: innerText[i],
                        id: element,
                      }).text(innerText[i])
                    )
                  )
                );
              }
            });
          } else {
            fieldBlock.html(this.$valueNotAvailableSeparator);
          }
          break;
        default:
          fieldBlock.html(text);
          break;
      }
    },
    cellFileUpload(obj) {
      this.model.values = [
        {
          key: this.selectedRecordCellField.formulaName,
          value: obj.Id,
          text: obj.Name,
        },
      ];
      this.saveCellHttpRequest(null);
    },
    fileOperation() {
      this.$refs.formItem.$refs.fileUpload.onUpload();
    },
    saveCellHttpRequest(pageItem) {
      var button = $(String.format(".btn-cell-save")),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.editModeDisabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.editModeSubmitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      var value = this.model.values[0];
      if (!String.isNullOrWhiteSpace(pageItem)) {
        value = this.$root.getPageItemFormValue(pageItem);
        this.model.values.push({
          key: this.selectedRecordCellField.formulaName,
          value: value.value.toString(),
          text: value.text,
        });
      }

      var requestUrl = "/rws-SetController-Edit";
      if (this.isNewRecord) {
        requestUrl = "/rws-SetController-ValidationToPostRecord";

        this.model = this.$root.modelCreate("#newForm");
        var changedItem = this.model.tableValues[0];
        changedItem.values = [
          {
            key: this.selectedRecordCellField.formulaName,
            value: value.value.toString(),
            text: value.text,
          },
        ];

        if (this.fieldValues) {
          this.fieldValues.forEach((fieldValue) => {
            changedItem.values.push(fieldValue);
          });
        }
        this.model.tableValues[0] = changedItem;
      }

      this.updateRequestSending = true;
      this.$appAxios
        .post(requestUrl, this.model)
        .then((response) => {
          this.updateRequestSending = false;
          var result = response.data,
            isNewPage = this.$route.meta.isNewPage,
            brResponse = result.businessRuleResponse,
            brErrors = brResponse.businessRuleResponses;

          this.$parent.inlineErrors = result.validations.filter(function (f) {
            return f.validationType.value == 3;
          });

          if (
            brErrors &&
            brErrors.length > 0 &&
            brResponse.notificationType !== "Tooltip"
          ) {
            this.$parent.businessRuleNotificationType =
              brResponse.notificationType;
            this.$parent.businessRuleErrors = brErrors;
            showModal(
              document.getElementById(
                String.format("modalInlineError_{0}", this.id)
              )
            );
          }

          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);

          if (result.isOk) {
            var selectedRow = $(
                String.format(".dx-data-row:eq({0})", this.selectedRowIndex)
              ),
              selectedCell = selectedRow.find(
                String.format("td:eq({0})", this.selectedCellIndex)
              );

            // selectedRow.addClass("row-updated");
            selectedCell.addClass("cell-updated");
            setTimeout(() => {
              // selectedRow.removeClass("row-updated");
              selectedCell.removeClass("cell-updated");
              if (isNewPage) {
                this.$emit(
                  "updatedCell",
                  this.model.values[0],
                  this.selectedRowData,
                  this.selectedRecordCellField
                );
              }
            }, 2000);

            this.updateCellHtml(selectedCell, value.value, value.text);

            if (!isNewPage) {
              this.$emit(
                "updatedCell",
                this.model.values[0],
                this.selectedRowData,
                this.selectedRecordCellField
              );
            }

            //default value trigger
            if (
              !this.$root.fieldTypes.select.includes(
                this.selectedRecordCellField.fieldType
              ) &&
              !this.$root.fieldTypes.file.includes(
                this.selectedRecordCellField.fieldType
              ) &&
              !this.$root.fieldTypes.checkbox.includes(
                this.selectedRecordCellField.fieldType
              )
            ) {
              this.$root.calculateTriggerEditCellField(
                this,
                selectedRow,
                selectedCell
              );
            }

            if (
              this.selectedRecordCellField.fieldType != "File" &&
              this.selectedRecordCellField.fieldType != "Image"
            ) {
              hideModal(document.getElementById(this.modalId));
            }
            createToast(
              this.selectedRecordCellField.name +
                this.$t(
                  "FieldWarnings.PageItemUptadeSuccessMessage",
                  {},
                  { locale: this.$store.state.activeLang }
                ),
              {
                showIcon: true,
                position: "top-right",
                type: "success",
                transition: "zoom",
              }
            );
          } else {
            if (!String.isNullOrWhiteSpace(result.msg)) {
              this.errors.push(result.msg);
            }

            if (!String.isNullOrWhiteSpace(result.message)) {
              this.errors.push(result.message);
            }

            if (!String.isNullOrWhiteSpace(result.validations)) {
              result.validations.forEach((element) => {
                this.errors.push(
                  String.format("{0}: {1}", element.fieldName, element.message)
                );
              });
            }

            createToast(
              this.errors.length > 0
                ? this.errors[0]
                : this.$t(
                    "BaseModelFields.ErrorMessage",
                    {},
                    { locale: this.$store.state.activeLang }
                  ),
              {
                showIcon: true,
                position: "top-right",
                type: "danger",
                transition: "zoom",
              }
            );
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    saveCell($event) {
      this.errors = [];
      this.model = {};
      $event.preventDefault();

      var form = $("#" + this.formId),
        pageItem = form.find(
          String.format(
            ".page-item[data-publicid='{0}']",
            this.selectedRecordCellField.publicId
          )
        );

      form.addClass("was-validated");

      if (!form[0].checkValidity() || !this.$root.forceFormValidation(form)) {
        return;
      }

      this.model = {
        customObjectPublicId: this.selectedRecordCellField.customObjectPublicId,
        customObjectKey: this.selectedRecordCellField.customObjectKeyName,
        pageLayoutId: "",
        recordPublicId: this.selectedRowData.PUBLICID,
        values: [],
      };

      if (
        this.selectedRecordCellField.fieldType == "File" ||
        this.selectedRecordCellField.fieldType == "Image"
      ) {
        this.fileOperation();
      } else {
        this.saveCellHttpRequest(pageItem);
      }
    },
  },
};
</script>
