<template>
  <div class="input-group">
    <input
      ref="spatialTextBox"
      type="text"
      :id="template.id"
      :name="template.id"
      class="form-control bg-light"
      spellcheck="false"
      :autocomplete="$isAutoComplete"
      maxlength="0"
      :disabled="isDisabled"
      v-model="latLong"
      @click="mapShow = !mapShow"
    />
    <div class="invalid-tooltip">
      {{
        this.$t(
          "FieldWarnings.TextNotValid",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
    </div>
    <button
      :disabled="isDisabled"
      class="btn btn-secondary h-41"
      type="button"
      @click="mapShow = !mapShow"
    >
      <span v-if="!mapShow">
        {{
          this.$t(
            "BaseModelFields.MapOpen",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </span>
      <span v-else>
        {{
          this.$t(
            "BaseModelFields.MapClose",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </span>
    </button>
    <div
      class="mapArea p-2 bg-light border w-100 mh-300"
      v-if="mapShow && !isDisabled"
    >
      <l-map
        class="h-250"
        ref="map"
        v-model:zoom="zoom"
        :minZoom="3"
        :maxZoom="15"
        :zoomAnimation="true"
        :center="[this.lat, this.lng]"
      >
        <l-tile-layer
          :url="mapUrl"
          layer-type="base"
          name="OpenStreetMap"
        ></l-tile-layer>
        <l-control-layers />
        <l-marker
          :lat-lng="[this.lat, this.lng]"
          draggable
          @dragend="markerDragEnd"
        >
          <l-tooltip>{{ this.lat }}, {{ this.lng }} </l-tooltip>
        </l-marker>
      </l-map>
      <div class="row mt-2" style="margin: 1px">
        <div class="col col-4">
          <input
            type="text"
            class="form-control form-control-sm"
            :placeholder="
              this.$t(
                'BaseModelFields.Latitude',
                {},
                { locale: this.$store.state.activeLang }
              )
            "
            v-model="lat"
          />
        </div>
        <div class="col col-4">
          <input
            type="text"
            class="form-control form-control-sm"
            :placeholder="
              this.$t(
                'BaseModelFields.Longitude',
                {},
                { locale: this.$store.state.activeLang }
              )
            "
            v-model="lng"
          />
        </div>
        <div class="col col-4">
          <div class="mt-1" role="group">
            <button
              class="btn btn-icon btn-warning me-2"
              type="button"
              @click="findLocation"
            >
              <i class="fas fa-map-marker-alt"></i></button
            ><button
              class="btn btn-icon btn-success"
              type="button"
              :disabled="
                String.isNullOrWhiteSpace(this.lat) ||
                String.isNullOrWhiteSpace(this.lng)
              "
              @click="save"
            >
              <i class="fas fa-check"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "leaflet/dist/leaflet.css";
import {
  LMap,
  LTileLayer,
  LControlLayers,
  LMarker,
  LTooltip,
} from "@vue-leaflet/vue-leaflet";
export default {
  name: "Spatial",
  props: ["template", "value", "valueId", "table", "rowData", "disabled"],
  components: {
    LMap,
    LTileLayer,
    LControlLayers,
    LMarker,
    LTooltip,
  },
  data() {
    return {
      latLong: null,
      lat: "",
      lng: "",
      valueStrFormat: "{0},{1}",
      isDisabled: this.disabled,
      zoom: 7,
      mapUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      mapShow: false,
    };
  },
  methods: {
    setValue(isSuccess, value) {
      this.latLong = value;

      if (isSuccess) {
        this.oldValue = value;
      }
    },
    markerDragEnd(event) {
      var location = event.target.getLatLng();
      this.lat = location.lat;
      this.lng = location.lng;
    },
    save() {
      this.latLong = String.format(this.valueStrFormat, this.lat, this.lng);
      this.template.parent.editCellTemplateValueChangedForSpatial(
        this.template,
        this.latLong
      );
      this.mapShow = false;
    },
    findLocation() {
      var self = this;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            var location = String.format(
                "{0}|{1}",
                position.coords.latitude,
                position.coords.longitude
              ),
              currentLocationInput = document.getElementById("CurrentLocation");

            if (currentLocationInput) currentLocationInput.val = location;

            Object.setCookie("CurrentLocation", location, 1);

            self.lat = position.coords.latitude;
            self.lng = position.coords.longitude;
          },
          function (error) {
            switch (error.code) {
              case error.POSITION_UNAVAILABLE:
              case error.TIMEOUT:
              case error.UNKNOWN_ERROR:
                self.getLocation();
                break;
              default:
                break;
            }
          }
        );
      }
    },
  },
  mounted() {
    if (String.isNullOrWhiteSpace(this.value)) {
      this.lat = "";
      this.lng = "";
      this.findLocation();
    }

    if (
      !this.template.field.fieldType.includes("Formula") &&
      this.value !== "CURRENT_USER_LOCATION" &&
      !String.isNullOrWhiteSpace(this.value)
    ) {
      this.latLong = this.value;

      if (this.value.includes(",")) {
        var splittedValue = this.value.split(",");
        this.lat = splittedValue[0];
        this.lng = splittedValue[1];
      }
    }
  },
};
</script>
