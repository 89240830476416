import axios from "axios";

function getAppConfigurationFile() {
  return new Promise((resolve) => {
    axios.get(process.env.VUE_APP_CONFIG_URL).then((response) => {
      resolve(response.data);
    });
  });
}

export { getAppConfigurationFile };
