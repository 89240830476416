import $ from 'jquery';
var systemSperator = process.env.VUE_APP_SYSTEM_SEPARATOR;

String.format = function () {
    var s = arguments[0];
    for (var i = 0; i < arguments.length - 1; i++) {
        var reg = new RegExp("\\{" + i + "\\}", "gm");
        s = s.replace(reg, arguments[i + 1]);
    }
    return s;
};

String.isNullOrWhiteSpace = function (str) {
    if (typeof (str) === 'undefined') return true;
    if (str == null) return true;
    var result = str.toString().replace(/\s/gi, '').length < 1;
    return result;
};

String.replaceAll = function (word, find, replace) {
    return word.replace(new RegExp(find.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
};

String.replaceAlphanumeric = function (str, newChar) {
    if (String.isNullOrWhiteSpace(str)) return "";
    if (String.isNullOrWhiteSpace(newChar)) newChar = "_";

    var searchAndReplaceStringKeys = [{
            search: "ü",
            replace: "u",
        },
        {
            search: "ğ",
            replace: "g",
        },
        {
            search: "ı",
            replace: "i",
        },
        {
            search: "ş",
            replace: "s",
        },
        {
            search: "ç",
            replace: "c",
        },
        {
            search: "ö",
            replace: "o",
        },
        {
            search: "Ü",
            replace: "U",
        },
        {
            search: "Ğ",
            replace: "G",
        },
        {
            search: "İ",
            replace: "I",
        },
        {
            search: "Ş",
            replace: "S",
        },
        {
            search: "Ç",
            replace: "C",
        },
        {
            search: "Ö",
            replace: "O",
        },
        {
            search: "0",
            replace: newChar,
        },
        {
            search: "1",
            replace: newChar,
        },
        {
            search: "2",
            replace: newChar,
        },
        {
            search: "3",
            replace: newChar,
        },
        {
            search: "4",
            replace: newChar,
        },
        {
            search: "5",
            replace: newChar,
        },
        {
            search: "6",
            replace: newChar,
        },
        {
            search: "7",
            replace: newChar,
        },
        {
            search: "8",
            replace: newChar,
        },
        {
            search: "9",
            replace: newChar,
        },
        {
            search: /\W+/g,
            replace: newChar,
        },
    ];
    searchAndReplaceStringKeys.forEach((item) => {
        str = str.replaceAll(item.search, item.replace);
    });

    if (!String.isNullOrWhiteSpace(str)) {
        var splitedStr = str.split(newChar),
            notNullStrItems = splitedStr.filter((f) => !String.isNullOrWhiteSpace(f));
        if (notNullStrItems.length == 0) {
            return "";
        } else {
            return notNullStrItems.join(newChar);
        }
    }

    return str;
};

String.createGetUrl = function (action, requestParameters) {
    if (String.isNullOrWhiteSpace(action)) return null;

    var searchUrl = action;
    if (requestParameters.length > 0) {
        requestParameters.forEach((element) => {
            var separator = searchUrl.includes("?") ? "&" : "?";
            searchUrl += `${separator}${element.key}=${element.value}`;
        });
    }

    return searchUrl;
};

String.numberWithCommas = function (value, activeLanguage) {
    if (String.isNullOrWhiteSpace(value)) return;

    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, activeLanguage == "en" ? "," : ".");
};

String.searchTextReplace = function (text) {
    if (!text) return "";

    return text
        .replaceAll(/İ/g, "I")
        .replaceAll(/Ö/g, "O")
        .replaceAll(/Ü/g, "U")
        .replaceAll(/Ğ/g, "G")
        .replaceAll(/Ç/g, "C")
        .replaceAll(/Ş/g, "S")
        .replaceAll(/ı/g, "i")
        .replaceAll(/ö/g, "o")
        .replaceAll(/ü/g, "u")
        .replaceAll(/ğ/g, "g")
        .replaceAll(/ç/g, "c")
        .replaceAll(/ş/g, "s");
};

String.newGuid = function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

String.isEmail = function (email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
}

String.isGuid = function (value) {
    var regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    var match = regex.exec(value);
    return match != null;
}

String.QueryString = function (param) {
    var href = window.location.href,
        url = href.slice(href.indexOf('?') + 1).split('&');
    for (var i = 0; i < url.length; i++) {
        var urlparam = url[i].split('=');
        if (urlparam[0] == param) {
            return urlparam[1];
        }
    }
    return "";
}

String.SetAttributes = function (el, attrs) {
    Object.keys(attrs).forEach(key => el.setAttribute(key, attrs[key]));
}

String.isJSON = function (str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

String.CapitalizeText = function (str) {
    if (typeof str !== 'string') {
        throw Error('Feed me string')
    } else if (!str) {
        return ''
    } else {
        str = str.trim();
        return str
            .split(' ')
            .map(s => {
                if (s.length == 1) {
                    return s.toUpperCase()
                } else {
                    const firstLetter = s.split('')[0].toUpperCase()
                    const restOfStr = s.substr(1, s.length).toLowerCase()
                    return firstLetter + restOfStr
                }
            })
            .join(' ')
    }
}

Object.isObject = function (obj) {
    return obj === Object(obj);
}

Object.isHTML = function (htmlString) {
    return /<[a-z][\s\S]*>/i.test(htmlString);
}

Object.IframeSettings = function (iframeId) {
    var iframe = $(`#${iframeId}`),
        src = iframe.attr('src');
    if (!src.includes("#")) return;

    iframe.contents().find('body').removeClass('aside-enabled aside-fixed');
    iframe.contents().find('body').addClass('iframe-page aside-none');
    
    iframe.contents().find('.sx-post').addClass("mt-2");

    iframe.contents()
        .find("#sx_aside, #sx_header, #sx_footer, #app-preloader")
        .remove();
}

Object.IframeCalcHeight = function (iframeId) {
    var iframe = document.getElementById(iframeId),
        src = iframe.attr('src'),
        marginOfError = 25;

    if (!iframe) return;

    if (!src.includes("#")) { //dış adresler
        iframe.height = "760px";
        return;
    }

    var iframeHeight =
        iframe.contentWindow.document.body
        .scrollHeight + marginOfError;
    iframe.height = `${iframeHeight}px`;
}

Object.setCookie = function (key, value, expiry) {
    var expires = new Date();
    expires.setTime(expires.getTime() + (expiry * 15 * 60 * 1000));
    document.cookie = key + '=' + value + ';expires=' + expires.toUTCString() + ';secure=true';
}

Object.readCookie = function (name) {
    var nameEq = encodeURIComponent(name) + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ')
            c = c.substring(1, c.length);
        if (c.indexOf(nameEq) === 0)
            return decodeURIComponent(c.substring(nameEq.length, c.length));
    }
    return null;
}

Object.base64ToBlob = function (base64) {
    var base64String = base64.split(','),
        sliceSize = 1024,
        byteChars = window.atob(base64String[1]),
        byteArrays = [],
        mime = base64String[0].split(':')[1].split(';')[0];

    for (var offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
        var slice = byteChars.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {
        type: mime
    });
}

Number.startTimer = function (elementId, seconds) {
    var element = document.getElementById(elementId);
    if (!element || String.isNullOrWhiteSpace(seconds)) return;

    var timeInSecs = parseInt(seconds),
        ticker = setInterval(function () {
            var secs = timeInSecs;
            if (secs > 0) {
                timeInSecs--;
            } else {
                clearInterval(ticker);
                return;
            }

            var mins = Math.floor(secs / 60);
            secs %= 60;
            var pretty = ((mins < 10) ? "0" : "") + mins + ":" + ((secs < 10) ? "0" : "") + secs;

            element.innerHTML = pretty;
        }, 1000);
}

Number.isFloat = function (n) {
    return Number(n) === n && n % 1 !== 0;
}

Number.isInt = function (n) {
    return Number(n) === n && n % 1 === 0;
}

Number.isNumber = function (e) {
    e = e ? e : window.event;
    var charCode = e.which ? e.which : e.keyCode;
    if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
    ) {
        e.preventDefault();
    } else {
        return true;
    };
}

Number.sum = function (array) {
    var total = 0;

    if (!array || (array && array.length == 0)) return total;

    array.forEach(item => {
        if (String.isNullOrWhiteSpace(item)) item = 0;

        total += Number(item);
    });

    return total;
}

Number.range = function (start, end) {
    start = Math.round(start);
    end = Math.round(end);
    var result = [];
    if (start > end) { // counting down
        for (let i = start; i >= end; i--) {
            result.push(i);
        }
    } else { // counting up
        for (let i = start; i <= end; i++) {
            result.push(i);
        }
    }
    return result;
}

Number.calcSeparatorRemove = function (value, activeLanguage) {
    if (String.isNullOrWhiteSpace(value)) return value;

    var returnedString = value.replace(".", "").replace(",", ".");
    if (activeLanguage == "en") {
        returnedString = value.replace(",", "");
    }

    return returnedString;
}

Number.priceFormat = function (price, digit, intlCode = "tr-TR", intlCurrencySymbol = "₺") {
    if (String.isNullOrWhiteSpace(digit)) {
        digit = 2;
    }

    var oldPrice = price;
    if (String.isNullOrWhiteSpace(price)) {
        price = "0";
    }

    var formattedOutput = new Intl.NumberFormat(intlCode, {
        style: 'currency',
        currency: 'TRY',
        minimumFractionDigits: digit,
    });

    return isNaN(price) ? oldPrice : formattedOutput.format(price).replace(intlCurrencySymbol, '');
}

Boolean.toBool = function (obj) {
    if (obj == undefined) return false;
    if (!obj.length) return false;
    return JSON.parse(obj.toString().toLowerCase());
}

Array.diff = function (arr1, arr2) {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) return [];

    return arr1
        .filter((x) => !arr2.includes(x))
        .concat(
            arr2.filter(
                (x) => !arr1.includes(x)
            )
        );
}

window.setModal = {
    Create: function (params) {

        var object = {
            id: '',
            globalClass: '',
            html: {
                header: '',
                body: '',
                footer: 'default'
            },
            settings: {
                hasStatic: false,
                widthClass: '', // modal-lg, modal-sm, modal-full-width
                maxHeight: $(window).height() - 60, // page height
                close: true
            }
        };

        var $set = $.extend(true, object, params);
        var modalId = "";

        if ($set.id != '') {
            modalId = $set.id;
            $set.id = 'id="' + $set.id + '"';
        }

        if ($set.html.footer.length && $set.html.footer == 'default') {
            $set.html.footer = '<button type="button" class="btn btn-default" data-bs-dismiss="modal">' + window.lblCancel + '</button>';
        }

        var staticKeys = "";
        if ($set.settings.hasStatic) {
            staticKeys = 'data-bs-backdrop="static" data-bs-keyboard="false"';
        }

        var modal = [
            '<div ' + $set.id + ' class="modal fade" ' + staticKeys + ' tabindex="-1" role="dialog" aria-hidden="true">',
            '<div class="modal-dialog ' + $set.settings.widthClass + '">',
            '<div class="modal-content">',
            $set.html.header.length ? ('<div class="modal-header">' + '<h5 class="modal-title">' + $set.html.header + '</h5>') + ($set.settings.close ? '<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>' : '') : '',

            '<div class="modal-body" style="overflow-x: auto; max-height: ' + $set.settings.maxHeight + 'px;">',
            $set.html.body,
            '</div>',

            $set.html.footer.length ? ('<div class="modal-footer">' + $set.html.footer + '</div>') : '',
            '</div>',
            '</div>',
            '</div>'
        ].join('');

        $('body').append(modal);
        $('body').append("<button type='button' class='btn hidden' data-bs-toggle='modal' data-bs-target='#" + modalId + "'></button>")
    },
    Remove: function (id) {
        $('body').find(id).remove();
        $('body').find('[data-bs-target="' + id + '"]').remove();
    },
    Toggle: function (id) {
        $('body').find('[data-bs-target="' + id + '"]').trigger('click');
    },
};

window.setUtil = {
    alert: function (params) {
        var options = {
            container: 'body',
            message: '',
            alertClass: 'alert-danger', //alert-success, alert-info, alert-warning, alert-danger,
            autoClose: true
        }

        var $set = $.extend(true, options, params);

        var alert = $('<div/>', {
                'class': 'alert alert-dismissible',
                'role': 'alert'
            })
            .append($('<button/>', {
                'type': 'button',
                'class': 'close',
                'data-dismiss': 'alert',
                'aria-label': 'Close'
            }).html('<span aria-hidden="true">&times;</span>'))
            .append(params.message);

        if ($set.autoClose) {
            alert.fadeTo(2000, 1000).slideUp(1000, function () {
                alert.alert('close');
            });
        }

        alert.addClass($set.alertClass);

        $($set.container).append(alert);
    },
    confirm: function (params) {
        var options = {
            title: window.confirmTitle,
            message: '',
            lblCancel: window.lblCancel,
            lblOk: window.lblOk
        }

        var $set = $.extend(true, options, params);

        window.setModal.Create({
            id: 'confirm-modal',
            html: {
                header: $set.title,
                body: $set.message,
                footer: '<button type="button" class="btn btn-sm btn-default" data-dismiss="modal">' + $set.lblCancel + '</button><button type="button" class="btn btn-sm btn-primary" data-dismiss="modal">' + $set.lblOk + '</button>'
            }
        });

        var modal = $('#confirm-modal');
        modal.find('.modal-footer').on('click', '.btn-primary', function () {
            params.callback(true);
        });

        modal.modal('show');

        modal.on('hidden.bs.modal', function () {
            params.callback(false);
            modal.remove();
        });
    },
    proggess: function (params) {
        var options = {
            container: 'body'
        }

        var $set = $.extend(true, options, params);

        var progress = $('<div/>', {
            'class': 'progress',
            'style': 'width:100%'
        }).html('<div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>');

        $($set.container).append(progress);

        return progress;
    }
};