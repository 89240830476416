<template>
  <div
    class="modal fade"
    :id="modalId"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    :aria-labelledby="`${modalId}-Label`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg sx-modal-responsive">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="`${modalId}-Label`">
            <i class="bi bi-trash"></i>
            {{
              $t(
                "Components.DeleteModal.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h5>
          <template v-if="this.relationRecordsForDelete.length > 0">
            <button
              type="button"
              class="btn-close"
              v-if="!buttonDisabled"
              @click="triggerDeleteAfterCallMethod"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </template>
          <template v-else>
            <button
              type="button"
              class="btn-close"
              v-if="!buttonDisabled"
              @click="clear"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </template>
        </div>
        <div class="modal-body">
          <template v-if="errors.length > 0">
            <AlertBox type="warning" :isModal="true" :messages="errors" />
            <template v-if="relationCheckForDelete.length > 0">
              <table class="table table-hover fs-5 mb-0">
                <thead class="table-primary">
                  <tr>
                    <th>
                      {{
                        this.$t(
                          "BaseModelFields.SerialNo",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                    <th>
                      {{
                        this.$t(
                          "BaseModelFields.RelationRecordCustomObjectName",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                    <th>
                      {{
                        this.$t(
                          "BusinessRules.Details",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(relationRecord, i) in relationCheckForDelete"
                    :key="i"
                  >
                    <td class="text-capitalize">
                      {{ ++i }}
                    </td>
                    <td class="text-capitalize">
                      {{ relationRecord.customObjectName }}
                    </td>
                    <td>
                      <a
                        :href="`#/set/detail/${relationRecord.customObjectKey}/${relationRecord.recordPublicId}`"
                        target="_blank"
                        class="btn btn-primary"
                        ><i class="fas fa-external-link-alt"></i>
                        {{
                          this.$t(
                            "History.LogDetail",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
            <template v-if="relationRecordsForDelete.length > 0">
              <div class="row">
                <div class="col">
                  <div class="counter-box border">
                    <span class="counter">{{
                      relationRecordsForDelete.length
                    }}</span>
                    <p>
                      {{
                        $t(
                          "Components.DeleteModal.ObjectCount",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </p>
                  </div>
                </div>
                <template v-if="deleteResponse.isRelationRecordsDeleteSync">
                  <div class="col">
                    <div class="counter-box bg-success">
                      <span class="counter text-white">{{
                        Number.sum(
                          relationRecordsForDelete.map((m) => m.successCount)
                        )
                      }}</span>
                      <p class="text-white">
                        {{
                          $t(
                            "BaseModelFields.Successful",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="col">
                    <div class="counter-box bg-danger">
                      <span class="counter text-white">{{
                        Number.sum(
                          relationRecordsForDelete.map((m) => m.failCount)
                        )
                      }}</span>
                      <p class="text-white">
                        {{
                          $t(
                            "BaseModelFields.Failed",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="col">
                    <div class="counter-box bg-warning">
                      <span class="counter text-white">{{
                        Number.sum(relationRecordsForDelete.map((m) => m.count))
                      }}</span>
                      <p class="text-white">
                        {{
                          $t(
                            "BaseModelFields.Total",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </template>
              </div>
              <table class="table table-hover fs-5 mb-0 mt-2">
                <thead class="table-primary">
                  <tr>
                    <th>
                      {{
                        this.$t(
                          "BaseModelFields.SerialNo",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                    <th>
                      {{
                        this.$t(
                          "BaseModelFields.RelationRecordCustomObjectName",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                    <th class="text-center">
                      {{
                        this.$t(
                          "BaseModelFields.Total",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                    <th
                      class="text-center"
                      v-if="deleteResponse.isRelationRecordsDeleteSync"
                    >
                      {{
                        this.$t(
                          "BaseModelFields.Successful",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                    <th
                      class="text-center"
                      v-if="deleteResponse.isRelationRecordsDeleteSync"
                    >
                      {{
                        this.$t(
                          "BaseModelFields.Failed",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(relationRecord, i) in relationRecordsForDelete"
                    :key="i"
                  >
                    <td class="text-capitalize">
                      {{ ++i }}
                    </td>
                    <td class="text-capitalize">
                      {{ relationRecord.customObjectKey }}
                    </td>
                    <td class="text-center">
                      {{ relationRecord.count }}
                    </td>
                    <td
                      class="text-center text-success"
                      v-if="deleteResponse.isRelationRecordsDeleteSync"
                    >
                      {{ relationRecord.successCount }}
                    </td>
                    <td
                      class="text-center text-danger"
                      v-if="deleteResponse.isRelationRecordsDeleteSync"
                    >
                      {{ relationRecord.failCount }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </template>
          <template v-else>
            <i class="bi bi-exclamation-circle text-danger me-1"></i>
            <template
              v-if="
                !String.isNullOrWhiteSpace(
                  relationalRecordDeleteCustomObjectNames
                )
              "
            >
              <span
                class="text-danger fs-5"
                v-html="
                  $t(
                    'Components.DeleteModal.DescriptionWithCustomObjectNames',
                    {},
                    { locale: this.$store.state.activeLang }
                  ).replaceAll(
                    'CUSTOM_OBJECTS',
                    relationalRecordDeleteCustomObjectNames
                      .split($systemSeparator)
                      .join(', ')
                  )
                "
              >
              </span>
            </template>
            <template v-else>
              <span class="text-danger fs-5">
                {{
                  $t(
                    "Components.DeleteModal.Description",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </span>
            </template>
          </template>
        </div>
        <div
          class="modal-footer"
          :class="
            errors.length == 0
              ? 'justify-content-between'
              : 'justify-content-right'
          "
        >
          <div v-if="errors.length == 0" :class="{ shake: shakeAnimate }">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                :id="`${modalId}-DeleteRecordCheck`"
                :name="`${modalId}-DeleteRecordCheck`"
                :checked="confirmDeletion"
                v-model="confirmDeletion"
                :disabled="buttonDisabled"
              />
              <label
                class="form-check-label ms-2 fw-bold delete-datacheck-label fs-5 no-selection"
                :id="`${modalId}-DeleteRecordCheck`"
                @click="confirmDeletion = !confirmDeletion"
                >{{
                  $t(
                    "Components.DeleteModal.IConfirmDeletion",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label
              >
            </div>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-danger btn-sm me-2 delete-record"
              v-if="errors.length == 0"
              :disabled="buttonDisabled"
              @click="deleteRecord"
            >
              <span>
                <i class="fas fa-trash"></i>
                {{
                  $t(
                    "Components.DeleteModal.DeleteButton",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </span>
            </button>
            <template v-if="this.relationRecordsForDelete.length > 0">
              <button
                type="button"
                class="btn btn-success btn-sm btn-close-modal"
                @click="triggerDeleteAfterCallMethod"
                data-bs-dismiss="modal"
              >
                <i class="fas fa-times"></i>
                {{
                  $t(
                    "BaseModelFields.Close",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </button>
            </template>
            <template v-else>
              <button
                type="button"
                class="btn btn-success btn-sm btn-close-modal"
                @click="clear"
                v-if="!this.buttonDisabled"
                data-bs-dismiss="modal"
              >
                <i class="fas fa-times"></i>
                {{
                  $t(
                    "BaseModelFields.Cancel",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { hideModal } from "@/core/helpers/dom";
import AlertBox from "../shared/AlertBox.vue";
import $ from "jquery";
export default {
  name: "DeleteModal",
  props: [
    "id",
    "deleteRecordId",
    "customObjectKey",
    "relationalRecordDeleteCustomObjectNames",
  ],
  components: {
    AlertBox,
  },
  data() {
    return {
      modalId: String.isNullOrWhiteSpace(this.id)
        ? String.format("modalDelete")
        : String.format("modalDelete_{0}", this.id),
      actionName: "rws-SetController-DeleteRecord",
      errors: [],
      shakeAnimate: false,
      confirmDeletion: false,
      buttonDisabled: false,
      submitButtonHtml: String.format(
        '<span class="loading-block d-flex justify-content-center align-items-center"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="ms-2">{0}</span></span>',
        this.$t(
          "BaseModelFields.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      relationCheckForDelete: [],
      relationRecordsForDelete: [],
      deleteResponse: null,
    };
  },
  methods: {
    triggerDeleteAfterCallMethod() {
      this.$emit("deleteAfterCallMethod");
      this.clear();
    },
    shake() {
      this.shakeAnimate = true;
      setTimeout(() => {
        this.shakeAnimate = false;
      }, 3000);
    },
    clear: function () {
      this.confirmDeletion = false;
      this.buttonDisabled = false;
      this.errors = [];
      this.relationCheckForDelete = [];
      this.relationRecordsForDelete = [];
      this.deleteResponse = null;
    },
    success: function ($firstSpan, $loadingBlock) {
      $firstSpan.show();
      $loadingBlock.remove();
      this.buttonDisabled = false;
      this.confirmDeletion = false;
      hideModal(document.getElementById(this.modalId));
    },
    error: function ($firstSpan, $loadingBlock) {
      $firstSpan.show();
      $loadingBlock.remove();
      this.buttonDisabled = false;
      this.confirmDeletion = false;
    },
    getMessage(msg) {
      if (msg === "relational_record_found") {
        return this.$t(
          "BaseModelFields.RelationalRecordFound",
          {},
          { locale: this.$store.state.activeLang }
        );
      } else if (msg === "deleted_record_relations_view") {
        return this.$t(
          "Components.DeleteModal.DeletedRelationalRecordsView",
          {},
          { locale: this.$store.state.activeLang }
        );
      } else if (msg === "deleting_record_relations_view") {
        return this.$t(
          "Components.DeleteModal.DeletingRelationalRecordsView",
          {},
          { locale: this.$store.state.activeLang }
        );
      }
      return msg;
    },
    deleteRecord: function () {
      if (
        !this.confirmDeletion ||
        String.isNullOrWhiteSpace(this.deleteRecordId) ||
        String.isNullOrWhiteSpace(this.customObjectKey)
      ) {
        this.shake();
        return;
      }

      this.buttonDisabled = true;
      this.errors = [];

      var button = $(String.format(".delete-record")),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block");

      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.relationCheckForDelete = [];
      this.relationRecordsDeleteResponse = [];
      this.deleteResponse = null;
      let self = this;
      self.$appAxios
        .delete(
          String.format(
            "{0}/{1}/{2}",
            this.actionName,
            this.customObjectKey,
            this.deleteRecordId
          )
        )
        .then((response) => {
          const result = response.data;
          this.deleteResponse = result;

          if (result.isOk) {
            var relationRecordsDeleteResponse =
              result.relationRecordsDeleteResponse;
            if (relationRecordsDeleteResponse.length > 0) {
              self.error(firstSpan, loadingBlock);
              if (result.isRelationRecordsDeleteSync) {
                self.errors.push(
                  this.getMessage("deleted_record_relations_view")
                );
              } else {
                self.errors.push(
                  this.getMessage("deleting_record_relations_view")
                );
              }

              this.relationRecordsForDelete = relationRecordsDeleteResponse;
            } else {
              self.success(firstSpan, loadingBlock);
              self.$emit("deleteAfterCallMethod");
            }
          } else {
            var relationCheckForDeleteReponse =
              result.relationCheckForDeleteReponse;
            if (relationCheckForDeleteReponse.isRelationalRecordFound) {
              this.relationCheckForDelete.push({
                customObjectPublicId:
                  relationCheckForDeleteReponse.relationalRecordCustomObjectPublicId,
                customObjectName:
                  relationCheckForDeleteReponse.relationalRecordCustomObjectName,
                customObjectKey:
                  relationCheckForDeleteReponse.relationalRecordCustomObjectKey,
                recordPublicId:
                  relationCheckForDeleteReponse.relationalRecordPublicId,
              });
            }

            self.error(firstSpan, loadingBlock);
            self.errors.push(this.getMessage(result.message));
          }
        })
        .catch(function (error) {
          self.errors.push(error);
        });
    },
  },
};
</script>
