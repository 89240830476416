<template>
  <input
    type="text"
    :id="template.id"
    :name="template.id"
    class="dx-texteditor-input shadow-none"
    :autocomplete="$isAutoComplete"
    :spellcheck="$isTextSpellCheck"
    :disabled="disabled"
    v-model="phoneInputValue"
    @blur="onChangePhoneInput"
  />
</template>
<script>
export default {
  name: "PhoneInput",
  props: ["template", "value", "disabled", "rowData"],
  data() {
    return {
      phoneInputValue: this.value,
    };
  },
  methods: {
    onChangePhoneInput() {
      this.template.parent.editCellTemplateValueChangedForPhoneInput(
        this.template,
        this.phoneInputValue
      );
    },
  },
  mounted() {
    this.template.parent.$root.preparePhoneNumber(
      this.template.id,
      this.phoneInputValue
    );
  },
};
</script>
