<template>
  <div
    class="modal fade"
    :id="String.format('modalImageView_{0}', field.publicId)"
    tabindex="-1"
    :aria-labelledby="String.format('modalImageViewLabel_{0}', field.publicId)"
    aria-hidden="true"
  >
    <div class="modal-dialog sx-modal-responsive modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            :id="String.format('modalImageViewLabel_{0}', field.publicId)"
          >
            <i class="fas fa-images"></i>
            {{ field.name }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body text-center">
          <img
            v-for="(item, i) in values"
            :key="item"
            :class="`preview_${item}`"
            :alt="value.split(this.$systemSeparator)[i]"
            src=""
            class="img-fluid border"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-danger btn-sm"
            data-bs-dismiss="modal"
          >
            <i class="fa fa-times"></i>
            {{
              $t(
                "BaseModelFields.Close",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FileUploadModal",
  props: ["valueId", "value", "field"],
  components: {},
  data() {
    return {
      values: [],
    };
  },
  methods: {},
  mounted() {
    if (!String.isNullOrWhiteSpace(this.valueId)) {
      if (Object.keys(this.valueId).length) {
        this.values = this.valueId.split(this.$systemSeparator);
      }
    }
  },
};
</script>
