<template>
  <div
    class="modal fade"
    :id="modalId"
    tabindex="-1"
    :aria-labelledby="`${modalId}-Label`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl sx-modal-responsive">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="`${modalId}-Label`">
            <i
              class="bi bi-info-circle-fill"
              :class="{
                'me-1': !$route.meta.isListPage,
              }"
            ></i>
            <template v-if="!$route.meta.isListPage">
              {{ table.name }}
            </template>
            {{
              $t(
                "Components.ShortcutInformationModal.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <table
            class="table table-hover table-striped table-borderless fs-5 mb-0"
          >
            <thead class="table-info">
              <tr>
                <th>
                  {{
                    $t(
                      "Components.ShortcutInformationModal.Shortcut",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </th>
                <th>
                  {{
                    $t(
                      "Components.ShortcutInformationModal.Description",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in shortcuts" :key="i">
                <td>
                  <b>{{ item.key }}</b>
                </td>
                <td>
                  {{ item.message }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-danger btn-sm btn-close-modal"
            data-bs-dismiss="modal"
          >
            <i class="fas fa-times"></i>
            {{
              $t(
                "BaseModelFields.Close",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ShortcutInformationModal",
  props: ["id", "table", "viewFilter"],
  data() {
    return {
      modalId: "shortcutInformationModal_" + this.id,
      shortcuts: [
        {
          key: "Ctrl + Y",
          message: this.$t(
            "Components.ShortcutInformationModal.AddNewRecord",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "Ctrl + K",
          message: this.$t(
            "Components.ShortcutInformationModal.RecordSave",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "Tab",
          message: this.$t(
            "Components.ShortcutInformationModal.TabInformation",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "Shift + Tab",
          message: this.$t(
            "Components.ShortcutInformationModal.ShiftTabInformation",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "Space",
          message: this.$t(
            "Components.ShortcutInformationModal.SpaceInformation",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "Esc",
          message: this.$t(
            "Components.ShortcutInformationModal.EscInformation",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
    };
  },
  methods: {},
  mounted() {
    if (this.viewFilter && this.viewFilter.isCellEditingEnabled) {
      this.shortcuts.push({
        key: "Cell Double Click",
        message: this.$t(
          "Components.ShortcutInformationModal.CellEditingEnabled",
          {},
          { locale: this.$store.state.activeLang }
        ),
      });
    } else {
      this.shortcuts.push({
        key: "Row Double Click",
        message: this.$t(
          "Components.ShortcutInformationModal.RowEditingEnabled",
          {},
          { locale: this.$store.state.activeLang }
        ),
      });
    }
  },
};
</script>
